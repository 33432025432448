import { css } from "glamor";
import React from "react";
import { Progress } from "semantic-ui-react";
import { colors, spacing } from "../library/theme";

const container = css({
  paddingBottom: 0,
});
const wrapper = css({
  display: "flex",
  color: colors.grey,
  justifyContent: "space-between",
  "> p": {
    marginBottom: spacing.xs,
  },
});
const progressBar = css({
  marginBottom: "0 !important",
  backgroundColor: `${colors.greyLight} !important`,
  ".ui.progress .bar": {
    backgroundColor: `${colors.primaryLight} !important`,
  },
});

export default function CertificateProgress({
  progress: percent,
  coreLessonsCount = 0,
  coreLessonsWatchedCount = 0,
  showCounters = "bottom",
}) {
  const value =
    !!coreLessonsCount && !!coreLessonsWatchedCount && +coreLessonsCount <= 0
      ? 0
      : +coreLessonsWatchedCount / +coreLessonsCount;

  const progress = (percent || value) * 100;

  const counter = showCounters && (
    <div className={wrapper}>
      <small>{`${progress.toFixed(0)}% concluído`}</small>
    </div>
  );

  return (
    <div className={container}>
      {showCounters == "top" && counter}

      <Progress
        percent={progress}
        className={progressBar.toString()}
        size="tiny"
      />

      {showCounters == "bottom" && counter}
    </div>
  );
}
