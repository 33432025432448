import React from "react";
import { Modal } from "../library";
import CommentModerationFormContainer from "./CommentModerationFormContainer";

export default function CommentModerationModal({ comment, closeHandler }) {
  return (
    <Modal open onClose={closeHandler} closeIcon>
      <Modal.Header>
        Moderar o comentário de {comment?.meta?.user?.name}
      </Modal.Header>
      <Modal.Content>
        <CommentModerationFormContainer
          form={`updateComment${comment?.id}`}
          comment={comment}
          closeHandler={closeHandler}
        />
      </Modal.Content>
    </Modal>
  );
}
