const getModals = (action) => action.entities.modals;

export default function modalsReducer(state = {}, action) {
  switch (action.type) {
    case "MODALS_FETCHED":
      return { ...state, ...getModals(action) };
    case "MODAL_CLOSED":
      return {
        ...state,
        [action.modalId]: { ...state[action.modalId], closed: true },
      };
    default:
      return state;
  }
}
