import React from "react";
import { css } from "glamor";
import LessonListItemContainer from "../lessons/LessonListItemContainer";
import { spacing, uiColors } from "../library/theme";
import Spinner from "../library/Spinner";

const titleClass = css({
  color: uiColors.backgroundDark,
  textTransform: "uppercase",
  fontWeight: "bold",
  marginTop: spacing.m,
  marginBottom: spacing.m,
});

export default function LevelListItem({
  level,
  lessons,
  compact,
  compliant,
  loading,
}) {
  return (
    <>
      <h5 className={titleClass}>{level.name}</h5>

      {loading ? (
        <Spinner text="Carregando aulas..." wrapped />
      ) : (
        <>
          {lessons?.map((lesson, index) => (
            <LessonListItemContainer
              key={index}
              lesson={lesson}
              compact={compact}
              compliant={compliant}
            />
          ))}
        </>
      )}
    </>
  );
}
