import React from "react";
import { Field } from "redux-form";
import { spacing } from "../theme";
import Hint from "../Hint";
import Label from "./Label";

const content = {
  display: "flex",
  alignItems: "center",
  gap: spacing.xs,
};
const hintWrapper = {
  marginLeft: spacing.m,
  whiteSpace: "pre-line",
  fontSize: 12,
};

export default function RadioField(props) {
  const { name, value, hint, label, disabled, className } = props;
  const id = `${name}${value?.toString()}`;

  return (
    <div>
      <div style={content}>
        <Field
          id={id}
          name={name}
          type="radio"
          component="input"
          value={`${value}`}
          disabled={disabled}
          className={className}
        />
        <Label htmlFor={id} disabled={disabled}>
          {label || name}
        </Label>
      </div>
      <div>{hint && <Hint style={hintWrapper}>{hint}</Hint>}</div>
    </div>
  );
}
