import { connect } from "react-redux";
import { getInvoiceFirstAttachment } from "../../selectors/invoiceSelectors";
import InvoiceListItem from "./InvoicetListItem";

function mapStateToProps(state, ownProps) {
  const { invoiceId } = ownProps;

  return {
    attachment: getInvoiceFirstAttachment(state, invoiceId),
  };
}

export default connect(mapStateToProps)(InvoiceListItem);
