import React, { useRef, useCallback } from "react";
import {
  Form,
  Button,
  spacing,
  TextAreaField,
  ImageField,
  TextField,
  RadioField,
  typographyColors,
  uiColors,
  Link,
  RouteLeavingGuard,
  Icon,
  Popup,
  decorationColors,
} from "../library";
import { css } from "glamor";
import { IoIosClose } from "react-icons/io";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import getShortenName from "../../utils/getShortenName";

const formClass = css({
  padding: `${spacing.m} ${spacing.l} ${spacing.l} ${spacing.l}`,
  maxWidth: "600px",
}).toString();
const headerClass = css({
  paddingBottom: spacing.s,
  borderBottom: `1px solid ${uiColors.border}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: spacing.m,
});
const closeClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: spacing.xs,
  marginRight: `-${spacing.s}`,
  borderRadius: "50%",
  color: uiColors.border,
  cursor: "pointer",
  ":hover": {
    color: typographyColors.defaultBody,
    backgroundColor: "#00000020",
  },
});
const postDataClass = css({
  display: "flex",
  gap: spacing.m,
  justifyContent: "space-between",
});
const authorWrapperClass = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  color: typographyColors.defaultTitle,
});
const authorNameClass = css({ fontWeight: 600 });
const textAreaClass = css({
  padding: spacing.s,
  ":focus": {
    borderColor: `${decorationColors.decorationGreen} !important`,
  },
});
const postCategoryWrapper = css({
  display: "flex",
  gap: spacing.s,
});
const counterClass = css({
  margin: `0px ${spacing.s}`,
  textAlign: "right",
});
const faqClass = css({
  flex: 1,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
});
const avatarStyle = { height: "40px", width: "40px", borderRadius: "50%" };
const footerClass = css({
  marginTop: spacing.s,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const titleClass = css({
  fontWeight: "bolder",
  color: typographyColors.defaultBody,
});
const imageFieldWrapper = css({
  marginTop: `-${spacing.s}`,
});
const imageWrapperClass = css({
  marginTop: spacing.s,
  display: "inline-block",
  maxHeight: 406,
  ">img": {
    maxHeight: "inherit",
    maxWidth: "100%",
    float: "center",
  },
});
const ctaWrapper = css({
  marginTop: spacing.s,
  padding: spacing.xs,
  border: `1px solid ${uiColors.border}`,
  borderRadius: spacing.xxs,
});
const ctaTitle = css({
  marginBottom: spacing.s,
});
const radioClass = css({
  "[type=radio]": {
    accentColor: "#53a467",
  },
});
const focusColor = css({
  ":active, :focus": {
    borderColor: `${decorationColors.decorationGreen} !important`,
  },
});

const isFile = (obj) => obj && !!obj.lastModified;

export default function PostForm(props) {
  const {
    handleSubmit,
    error,
    submitSucceeded,
    dirty,
    pristine,
    submitting,
    invalid,
    user,
    text,
    postCategories,
    postCategory,
    closeHandler,
    imageUrl,
  } = props;

  const imageInputRef = useRef(null);
  const addImageHandler = useCallback(() => {
    imageInputRef?.current?.click();
  }, [imageInputRef]);
  const image = isFile(imageUrl) ? URL.createObjectURL(imageUrl) : imageUrl;

  return (
    <Form onSubmit={handleSubmit} error={error} className={formClass}>
      <RouteLeavingGuard
        shouldBlockNavigation={() => dirty && !submitSucceeded}
        header="Deseja realmente sair?"
        content="Todo conteúdo será perdido, deseja realmente sair?"
        confirmButton="Sair"
        cancelButton="Voltar a publicação"
        confirmColor={decorationColors.decorationGreen}
      />
      <div className={headerClass}>
        <div className={titleClass}>Nova publicação</div>
        <div onClick={closeHandler} className={closeClass}>
          <IoIosClose size={22} />
        </div>
      </div>
      <div className={postDataClass}>
        <div className={authorWrapperClass}>
          <img src={user?.avatarUrl} style={avatarStyle} />
          <span className={authorNameClass}>{getShortenName(user?.name)}</span>
        </div>
      </div>
      <div className={postCategoryWrapper}>
        {postCategories.map((postCategory) => (
          <RadioField
            name="postCategoryId"
            label={postCategory.name}
            value={postCategory.id}
            key={postCategory.name}
            className={radioClass}
          />
        ))}
      </div>
      <TextAreaField
        name="text"
        autoFocus
        placeholder="Escreva algo..."
        maxLength={!user?.isAdmin && postCategory?.maxLength}
        rows={5}
        required
        className={textAreaClass}
      />
      {user?.isAdmin && (
        <>
          <TextField
            name="videoUrl"
            placeholder="Vídeo embed (opcional)"
            fluid
            className={focusColor}
          />

          <div className={imageFieldWrapper}>
            <ImageField name="imageUrl" inputRef={imageInputRef} hidden />

            {imageUrl && (
              <div className={imageWrapperClass}>
                <img src={image} onClick={addImageHandler} />
              </div>
            )}
          </div>
          <div className={ctaWrapper}>
            <p className={ctaTitle}>CTA (opcional)</p>
            <TextField
              name="ctaTitle"
              placeholder="Título do Link/CTA (opcional)"
              fluid
              className={focusColor}
            />
            <TextField
              name="ctaUrl"
              placeholder="Url do Link/CTA"
              fluid
              className={focusColor}
            />
          </div>
        </>
      )}
      <div className={footerClass}>
        {user?.isAdmin ? (
          <Button
            type="button"
            secondary
            onClick={addImageHandler}
            icon={
              <Icon.Group>
                <Icon name="image" />
                <Icon name="add" corner="top right" />
              </Icon.Group>
            }
          />
        ) : (
          <div className={faqClass}>
            <AiOutlineQuestionCircle size={16} />
            <Link to="/faqs">Dúvidas? Clique aqui</Link>
          </div>
        )}
        <div>
          <Popup
            disabled={!user?.isAdmin}
            content="Limite desabilitado para moderador"
            position="top center"
            trigger={
              <span className={counterClass}>
                {text?.length || 0}/{postCategory?.maxLength}
              </span>
            }
          />
          <Button
            type="submit"
            color="green"
            disabled={invalid || pristine || submitting}
          >
            Publicar
          </Button>
        </div>
      </div>
    </Form>
  );
}
