import { connect } from "react-redux";
import { getAllCommunities } from "../../selectors/communitySelectors";
import { getAllInvoices } from "../../selectors/invoiceSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import { featureEnabled } from "../../selectors/configSelectors";
import MainMenu from "./MainMenu";

function mapStateToProps(state) {
  return {
    currentClassroomId: state.currentClassroomId,
    hasInvoices: getAllInvoices(state)?.length > 0,
    currentUser: getCurrentUser(state),
    communities: getAllCommunities(state),
  };
}

export default connect(mapStateToProps)(MainMenu);
