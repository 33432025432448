import { css, select } from "glamor";
import React, { useCallback, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import {
  buttonColors,
  spacing,
  stateColors,
  typographyColors,
  uiColors,
} from "./theme";

const iconClass = css({
  marginRight: spacing.s,
});
const activeClass = css(
  {
    transition: ".3s all",
    color: `${buttonColors.primary} !important`,
  },
  select(`& .${iconClass}`, {
    transition: ".3s opacity",
    opacity: 1,
  })
);
const wrapper = css({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  padding: `${spacing.s} ${spacing.m}`,
  fontSize: 16,
  color: `${typographyColors.defaultBody} !important`,
  transition: ".3s all",
  height: 58,
  "&:hover": {
    ...activeClass,
  },
});
const disabledClass = css({
  color: stateColors.empty,
  pointerEvents: "none",
  userSelect: "none",
  "&:hover": {
    borderBottom: "none",
  },
  "&:visited": {
    borderBottom: "none",
    color: stateColors.empty,
  },
});
const wrapperClass = css({
  "> a": {
    backgroundColor: uiColors.backgroundLightest,
    paddingLeft: spacing.xl,
    border: "none",
    ":hover": {
      backgroundColor: uiColors.backgroundLightest,
      border: "none",
    },
  },
});
const arrowStyle = {
  marginLeft: "auto",
};

export default function AccordionMenu(props) {
  const { label, icon, active, disabled, children, className, ...rest } = props;
  const baseClass = css(
    wrapper,
    className && className,
    active && activeClass,
    disabled && disabledClass
  );
  const Icon = icon;
  const [open, setOpen] = useState(active);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div>
      <div className={baseClass} {...rest} onClick={toggleOpen}>
        {icon && <Icon size={22} className={iconClass} />}
        {label} <RiArrowDownSLine size={14} style={arrowStyle} />
      </div>

      {open && <div className={wrapperClass}>{children}</div>}
    </div>
  );
}
