import { normalize, schema } from "normalizr";
import { change } from "redux-form";
import { apiGet } from "./helpers/api";

const postalCodeAddress = new schema.Entity("postalCodeAddresses", {
  meta: { city: new schema.Entity("cities") },
});

export const searchAddressForProfileEdit = (
  dispatch,
  { postalCode, formName }
) => {
  if (!postalCode || postalCode?.length < 8) return;
  dispatch(change(formName, "searchingAddress", true));

  return apiGet(`/api/v1/postal_codes/${postalCode}`)
    .then((response) => {
      const { neighborhood, stateId, street, cityId } = response.body.data;

      dispatch({
        type: "CITIES_FETCHED",
        ...normalize(response.body.data, postalCodeAddress),
      });
      dispatch(change(formName, "searchingAddress", false));
      dispatch(change(formName, "searchedZipCode", postalCode));
      if (street) {
        dispatch(change(formName, "address", street));
      }
      if (neighborhood) {
        dispatch(change(formName, "neighborhood", neighborhood));
      }
      if (stateId) {
        dispatch(change(formName, "stateId", stateId));
      }
      if (cityId) {
        dispatch(change(formName, "cityId", cityId));
      }
      dispatch(change(formName, "searchedCityId", cityId));
      dispatch(change(formName, "searchedAddress", street));
      dispatch(change(formName, "searchedNeighborhood", neighborhood));
      dispatch(change(formName, "searchedStateId", stateId));
    })
    .catch((err) => {
      dispatch(change(formName, "searchingAddress", false));
    });
};
