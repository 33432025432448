const getContracts = (action) => action.entities.contracts;

export default function contractsReducer(state = {}, action) {
  switch (action.type) {
    case "CONTRACTS_FETCHED":
      return { ...state, ...getContracts(action) };
    default:
      return state;
  }
}
