const getFaqs = (action) => action.entities.faqs;

export default function faqsReducer(state = {}, action) {
  switch (action.type) {
    case "FAQS_FETCHED":
      return { ...state, ...getFaqs(action) };
    default:
      return state;
  }
}
