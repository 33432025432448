import { createSelector } from "reselect";
import { getLevelGroup } from "./levelGroupSelectors";
import orderByPosition from "./helpers/orderByPosition";

export const getLevels = (state) => state.entities.levels;
export const getLevel = (_, props) => props.level;
export const levelsLoading = (state) => state.levelsList.loading;

export const getAllLevels = createSelector(getLevels, (levels) =>
  Object.values(levels)
);

export const getLevelsByLevelGroup = createSelector(
  getLevelGroup,
  getLevels,
  (levelGroup, levels) =>
    levels &&
    Object.values(levels)
      .filter((level) => level.levelGroupId === levelGroup.id)
      .sort(orderByPosition)
);
