import React from "react";
import { Modal } from "../library";
import PostComplaintFormContainer from "./PostComplaintFormContainer";

export default function PostComplaintFormModal({
  communityId,
  postId,
  closeHandler,
}) {
  return (
    <Modal
      open
      onClose={closeHandler}
      size="tiny"
      closeOnDimmerClick={false}
      content={
        <PostComplaintFormContainer
          postId={postId}
          communityId={communityId}
          closeHandler={closeHandler}
        />
      }
    />
  );
}
