import { apiGet, apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";

const modalSchema = new schema.Entity("modals");

export const markAsSeen = (dispatch, modalId, userId) => {
  return apiPost("/api/v1/modals_users")
    .send({ modalId, userId })
    .then(() => {
      dispatch({ type: "MODAL_CLOSED", modalId });
    });
};

export const fetchModals = (dispatch) => {
  apiGet("/api/v1/modals/").then((response) => {
    dispatch({
      type: "MODALS_FETCHED",
      ...normalize(response.body.data, new schema.Array(modalSchema)),
    });
  });
};
