import { replace } from "connected-react-router";
import { css } from "glamor";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
import { Confirm } from "semantic-ui-react";
import { isString } from "../../utils/isString";
import {
  uiColors,
  typographyColors,
  spacing,
  decorationColors,
  colors,
} from "./theme";

const headerWrapper = {
  display: "flex",
  gap: spacing.s,
  alignItems: "center",
  color: typographyColors.defaultTitle,
  padding: `${spacing.s} ${spacing.s} ${spacing.s} ${spacing.m}`,
  fontSize: 16,
  marginTop: spacing.xxs,
};

const RouteLeavingGuard = ({
  when,
  shouldBlockNavigation = () => true,
  className,
  size = "mini",
  header,
  headerIcon,
  content,
  cancelButton = "Cancelar",
  confirmButton = "Confirmar",
  confirmColor = decorationColors.decorationPurple,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const hasSufficientParams = header && content;

  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, shouldBlockNavigation]
  );

  const handleConfirmNavigationClick = useCallback(() => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      dispatch(replace(lastLocation.pathname));
    }
  }, [dispatch, confirmedNavigation, lastLocation]);

  const baseClass = css({
    backgroundColor: uiColors.fieldBackground,
    height: "210px",
    width: "410px",
    ".ui.modal>.content": {
      color: `${typographyColors.defaultBody} !important`,
      padding: "1.5rem !important",
    },
    ".ui.modal>.actions": {
      borderTop: "none !important",
      backgroundColor: `${uiColors.fieldBackground} !important`,
      "> .ui.primary.button": {
        backgroundColor: `${confirmColor} !important`,
        color: "white !important",
        border: "none !important",
        ":hover": {
          backgroundColor: `${confirmColor}DD !important`,
          color: "white !important",
          border: "none !important",
        },
      },
      "> .ui.button": {
        backgroundColor: `${colors.white} !important`,
        color: "black !important",
        border: `2px solid ${colors.greyLight}`,
        ":hover": {
          backgroundColor: `${colors.greyLighter} !important`,
          color: "black !important",
          border: `2px solid ${colors.greyLight}`,
        },
      },
    },
  });

  return hasSufficientParams ? (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Confirm
        className={css(baseClass, className)}
        open={modalVisible}
        header={
          isString(header) ? (
            <div style={headerWrapper}>
              {headerIcon ? headerIcon : null}
              <span>{header}</span>
            </div>
          ) : (
            header
          )
        }
        content={content}
        size={size}
        cancelButton={cancelButton}
        confirmButton={confirmButton}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  ) : null;
};
export default RouteLeavingGuard;
