import React, { useCallback } from "react";
import { connect } from "react-redux";
import Modal from "./Modal";
import { getCurrentUser } from "../../selectors/userSelectors";
import {
  getModalForCommunity,
  getModalForHome,
} from "../../selectors/modalSelectors";
import { markAsSeen } from "../../processes/modalProcesses";

function ModalContainer(props) {
  const { dispatch, modal, currentUser } = props;

  const skipHandler = useCallback(() => {
    markAsSeen(dispatch, modal?.id, currentUser?.id);
  }, [dispatch, modal, currentUser]);

  const closeHandler = useCallback(() => {
    dispatch({ type: "MODAL_CLOSED", modalId: modal?.id });
  }, [modal]);

  return (
    <Modal closeHandler={closeHandler} skipHandler={skipHandler} {...props} />
  );
}

function mapStateToProps(state, ownProps) {
  const { communityId } = ownProps;

  return {
    currentUser: getCurrentUser(state),
    modal: communityId
      ? getModalForCommunity(state, communityId)
      : getModalForHome(state),
  };
}

export default connect(mapStateToProps)(ModalContainer);
