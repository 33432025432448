import React from "react";
import LobbyLayout from "../lobby/LobbyLayout";
import NewPasswordFormContainer from "./NewPasswordFormContainer";

export default function NewPasswordPage() {
  return (
    <LobbyLayout>
      <NewPasswordFormContainer />
    </LobbyLayout>
  );
}
