import React from "react";
import { Container, Grid } from "semantic-ui-react";
import NewPasswordFormContainer from "./NewPasswordFormContainer";

export default function NewPasswordLoggedPage() {
  return (
    <Container>
      <Grid padded centered>
        <Grid.Column computer={8} mobile={16} textAlign="center">
          <NewPasswordFormContainer logged />
        </Grid.Column>
      </Grid>
    </Container>
  );
}
