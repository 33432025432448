import React from "react";
import { css } from "glamor";
import AnnotationListItemContainer from "./AnnotationListItemContainer";
import { spacing } from "../library/theme";

const container = css({
  position: "relative",
  height: "100%",
  overflow: "auto",
});
const listWrapper = css({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
});

export default function AnnotationsList({ annotations }) {
  return (
    <div className={container}>
      <div className={listWrapper}>
        {annotations.map((annotation, index) => (
          <AnnotationListItemContainer key={index} annotation={annotation} />
        ))}
      </div>
    </div>
  );
}
