import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const classroom = new schema.Entity("classrooms");

export const fetchClassrooms = (dispatch) => {
  dispatch({ type: "CLASSROOMS_FETCH_REQUESTED" });
  apiGet("/api/v1/classrooms/").then((response) => {
    dispatch({
      type: "CLASSROOMS_FETCHED",
      ...normalize(response.body.data, new schema.Array(classroom)),
    });
  });
};

export const fetchClassroom = (dispatch, classroomId) => {
  apiGet(`/api/v1/classrooms/${classroomId}`).then((response) => {
    dispatch({
      type: "CLASSROOM_FETCHED",
      ...normalize([response.body.data], new schema.Array(classroom)),
    });
  });
};
