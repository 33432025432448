import React from "react";
import { Menu, Dropdown } from "semantic-ui-react";

const iconName = (type) => {
  switch (type) {
    case "PDF":
      return "file pdf outline";
    case "MP3":
      return "file audio outline";
    case "XLSX":
      return "file excel outline";
    case "DOC":
      return "file outline";
    case "MIND":
      return "sitemap";
    default:
      return "cloud download";
  }
};

export default function AttachmentList(props) {
  return (
    <Menu compact>
      <Dropdown simple item text={props.title} direction="left">
        <Dropdown.Menu>
          {props.attachments.map((attachment, index) => {
            return (
              <Dropdown.Item
                as="a"
                key={index}
                text={attachment.name || `Arquivo ${index + 1}`}
                href={attachment.url}
                target="_blank"
                icon={iconName(attachment.fileType)}
              />
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
}
