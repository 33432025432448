import React from "react";
import { connect } from "react-redux";
import LobbyRoutes from "./LobbyRoutes";
import LmsRoutes from "./LmsRoutes";

function RoutesContainer({ history, loggedIn, ...rest }) {
  return loggedIn ? <LmsRoutes {...rest} /> : <LobbyRoutes {...rest} />;
}

function mapStateToProps({ currentUserId }) {
  return {
    loggedIn: !!currentUserId,
  };
}

export default connect(mapStateToProps)(RoutesContainer);
