import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const postCategoriesSchema = new schema.Entity("postCategories");

export const fetchPostCategories = (dispatch) => {
  return apiGet(`/api/v1/post_categories`).then((response) => {
    dispatch({
      type: "POST_CATEGORIES_FETCHED",
      ...normalize(response.body.data, [postCategoriesSchema]),
    });
  });
};
