import React from "react";
import { css } from "glamor";

const layout = css({
  display: "flex",
});
const verticalClass = css({
  flexDirection: "column",
});

export default function Menu({
  children,
  vertical,
  className,
  style,
  ...rest
}) {
  const baseClass = css(
    layout,
    vertical && verticalClass,
    className && className
  );
  return (
    <div {...rest} className={baseClass} style={style}>
      {children}
    </div>
  );
}
