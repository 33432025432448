import React, { useCallback } from "react";
import { connect } from "react-redux";
import { getLike } from "../../selectors/likeSelectors";
import { deleteLike } from "../../processes/likeProcesses";
import LikeButton from "./LikeButton";

function LikeButtonContainer(props) {
  const { dispatch, like } = props;

  const deleteLikeHandler = useCallback(() => {
    if (like) {
      deleteLike(dispatch, like?.id);
    }
  }, [like]);

  return <LikeButton deleteLikeHandler={deleteLikeHandler} {...props} />;
}

function mapStateToProps(state, { likeableType, likeableId }) {
  return {
    like: getLike(state, { likeableType, likeableId }),
  };
}

export default connect(mapStateToProps)(LikeButtonContainer);
