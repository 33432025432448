import React from "react";
import LevelGroupListItemContainer from "../levelGroups/LevelGroupListItemContainer";

export default function ClassroomContents({ levelGroups, compact, compliant }) {
  return levelGroups?.map((levelGroup, index) => (
    <LevelGroupListItemContainer
      key={index}
      index={index}
      levelGroup={levelGroup}
      compact={compact}
      compliant={compliant}
    />
  ));
}
