import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import { getPostComments } from "../../selectors/commentSelectors";
import { getPostById, getPinnedPostIds } from "../../selectors/postSelectors";
import { getBadgesByIds } from "../../selectors/configSelectors";
import {
  deletePost,
  togglePinPost,
  toggleCommentsEnabled,
  swapPinnedPostOrder,
} from "../../processes/postProcesses";
import Post from "./Post";

function PostContainer(props) {
  const { dispatch, postId, post, pinnedPostIds } = props;

  const [commentsPagination, commentsPaginationState] = useState(1);

  const deleteHandler = useCallback(() => {
    deletePost(dispatch, post);
  }, [dispatch, post]);

  const toggleCommentsHandler = useCallback(() => {
    toggleCommentsEnabled(dispatch, post);
  }, [dispatch, post]);

  const togglePinHandler = useCallback(() => {
    togglePinPost(dispatch, { postId, pinnedPostIds });
  }, [dispatch, postId, pinnedPostIds]);

  const orderPinHandler = useCallback(() => {
    swapPinnedPostOrder(dispatch, { postId, pinnedPostIds });
  }, [dispatch, postId, pinnedPostIds]);

  const commentsPaginationHandler = useCallback(() => {
    commentsPaginationState(commentsPagination + 1);
  }, [commentsPagination]);

  return (
    <Post
      deleteHandler={deleteHandler}
      togglePinHandler={togglePinHandler}
      orderPinHandler={orderPinHandler}
      toggleCommentsHandler={toggleCommentsHandler}
      commentsPagination={commentsPagination}
      commentsPaginationHandler={commentsPaginationHandler}
      {...props}
    />
  );
}

function mapStateToProps(state, { postId }) {
  const post = getPostById(state, postId);

  return {
    post,
    user: getCurrentUser(state),
    badges: getBadgesByIds(state, post?.meta?.user?.badgeIds),
    comments: getPostComments(state, post?.id),
    pinnedPostIds: getPinnedPostIds(state, post?.communityId),
  };
}

export default connect(mapStateToProps)(PostContainer);
