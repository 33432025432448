import React from "react";
import Page from "../library/Page";
import { Table } from "semantic-ui-react";
import ContractListItem from "./ContractListItem";
import Spinner from "../library/Spinner";
import { spacing, stateColors } from "../library/theme";
import { css } from "glamor";

const emptyClass = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: spacing.l,
  ">h4": {
    color: stateColors.empty,
  },
});

export default function ContractsList(props) {
  const { classroomsContracts, loading } = props;

  if (loading) return <Spinner text="Carregando contratos..." wrapped />;

  return (
    <Page title="Contratos">
      {classroomsContracts.length ? (
        <Table>
          <Table.Body>
            {classroomsContracts.map((classroomsContract) => (
              <ContractListItem
                key={`contract${classroomsContract.id}`}
                classroomsContract={classroomsContract}
              />
            ))}
          </Table.Body>
        </Table>
      ) : (
        <div className={emptyClass}>
          <h4>Sem contratos</h4>
        </div>
      )}
    </Page>
  );
}
