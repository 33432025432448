import React from "react";
import { connect } from "react-redux";
import LevelListItem from "./LevelListItem";
import {
  getLessonsByLevel,
  lessonsLoading,
} from "../../selectors/lessonSelectors";

function LevelListItemContainer(props) {
  if (!props.lessons.length) return null;
  return <LevelListItem {...props} />;
}

function mapStateToProps(state, ownProps) {
  return {
    loading: lessonsLoading(state),
    lessons: getLessonsByLevel(state, ownProps),
  };
}

export default connect(mapStateToProps)(LevelListItemContainer);
