import React from "react";
import PostContainer from "../posts/PostContainer";
import { Spinner, Page, stateColors, spacing, Button, Link } from "../library";
import CommunityAccessModal from "./CommunityAccessModal";
import { useLocation } from "react-router-dom";
import { useFragmentRouting } from "../../utils/useFragmentRouting";
import ModalContainer from "../modal/ModalContainer";

const emptyStyle = {
  color: stateColors.empty,
  paddingTop: spacing.xxl,
  textAlign: "center",
};

export default function Community({
  community,
  loading,
  posts,
  requestAccessHandler,
}) {
  useFragmentRouting(useLocation(), [loading]);
  const contentAccess =
    community?.accessStatus == "approved" &&
    community?.acceptedTerms &&
    community?.hasAccess &&
    community?.compliant &&
    !community?.isBanned;

  return loading ? (
    <Spinner text="Carregando comunidade..." wrapped />
  ) : (
    <Page
      title={community?.name}
      externalLink="https://manual.ibft.com.br/RegrasComunidade"
      action={
        <Link
          component={Button}
          color="green"
          to={`/communities/${community?.id}/posts/new`}
        >
          Nova publicação
        </Link>
      }
    >
      {community && (
        <>
          <CommunityAccessModal
            community={community}
            requestAccessHandler={requestAccessHandler}
          />
          {contentAccess && <ModalContainer communityId={community?.id} />}
        </>
      )}
      {community && posts?.length ? (
        posts.map(({ id }) => <PostContainer postId={id} key={id} />)
      ) : (
        <h4 style={emptyStyle}>Sem postagens publicadas no momento</h4>
      )}
    </Page>
  );
}
