import React from "react";
import Button from "../library/Button";
import Link from "../library/Link";
import { spacing } from "../library/theme";

const container = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  marginTop: spacing.m,
};

const actionButtonStyle = {
  marginBottom: spacing.xxs,
};

export default function LessonActions(props) {
  const { actions } = props;

  if (!actions) {
    return null;
  }

  return (
    <div style={container}>
      {actions.map((action) => (
        <Link
          component={Button}
          href={action.url}
          target="_blank"
          color={action?.color}
          style={actionButtonStyle}
          size="huge"
        >
          {action.title}
        </Link>
      ))}
    </div>
  );
}
