import { createSelector } from "reselect";
import { getCurrentLesson } from "./lessonSelectors";

const getRatings = (state) => state.entities.ratings;
const getId = (_, id) => id;

const getLessonRating = createSelector(
  getRatings,
  getId,
  (ratings, ratingableId) =>
    Object.values(ratings).filter(
      (rate) =>
        rate.ratingableType === "Lesson" && rate.ratingableId === ratingableId
    )[0]
);

export const canRateLesson = createSelector(
  getLessonRating,
  getCurrentLesson,
  (rating, lesson) => !rating && lesson?.watched && !!lesson?.videoUrl
);
