import React from "react";
import { css } from "glamor";
import FieldMessage from "./FieldMessage";
import Label from "./Label";
import FieldBlock from "./FieldBlock";
import Hint from "../Hint";
import { spacing } from "../theme";

const layout = {
  message: {
    position: "absolute",
    right: 0,
    top: "-18px",
    marginTop: `-${spacing.xxs}`,
  },
  label: {
    marginBottom: spacing.xxs,
    display: "block",
  },
};

const errorWrapper = css({
  textAlign: "left",
  position: "relative",
  display: "inline-block",
  width: "100%",
});

const defaultMessages = {
  required: "Campo obrigatório",
  email: "E-mail inválido",
  minSize: "Muito curto",
  date: "Data inválida",
  zipCode: "CEP inválido",
};

const defaultShortMessages = {
  required: "Obrigatório",
  email: "Ínvalido",
  minSize: "Muito curto",
  date: "Data inválida",
  zipCode: "CEP inválido",
};

function errorMessage(errorType, customMessages, short = false) {
  return (
    (customMessages && customMessages[errorType]) ||
    (short ? defaultShortMessages : defaultMessages)[errorType] ||
    errorType
  );
}

export default (InputComponent, selectProps) => (props) => {
  const {
    input = {},
    meta = {},
    style,
    label,
    hint,
    required,
    validationMessages,
    shortMessages,
    message,
    minSize, // eslint-disable-line no-unused-vars
    inline,
    ...rest
  } = props;
  const id = input.id || input.name;
  const error = meta.touched ? meta.error : undefined;
  const errorMsg = error
    ? errorMessage(error, validationMessages, shortMessages)
    : undefined;
  return (
    <FieldBlock inline={inline} style={{ ...style, width: rest.width }}>
      {label && (
        <Label
          style={layout.label}
          required={required}
          htmlFor={id}
          disabled={props.disabled}
        >
          {label}
        </Label>
      )}
      <span className={css(errorWrapper, { width: rest.width })}>
        <InputComponent
          {...selectProps({ ...input, id: id }, meta, rest)}
          error={!!error}
        />
        {errorMsg && (
          <FieldMessage
            id="formMessage"
            style={layout.message}
            message={errorMsg}
          />
        )}
        {message && (
          <FieldMessage
            type="success"
            style={layout.message}
            message={message}
          />
        )}
        {hint && <Hint>{hint}</Hint>}
      </span>
    </FieldBlock>
  );
};
