import { normalize, schema } from "normalizr";
import { apiGet, apiPost } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";

const communitySchema = new schema.Entity("communities");

export const fetchCommunities = (dispatch) => {
  return apiGet("/api/v1/communities").then((response) => {
    dispatch({
      type: "COMMUNITIES_FETCHED",
      ...normalize(response.body.data, [communitySchema]),
    });
  });
};

export const createCommunitiesUser = (dispatch, communityId) => {
  return apiPost(`/api/v1/communities/${communityId}/communities_users`).then(
    (response) => {
      dispatch({
        type: "COMMUNITY_FETCHED",
        ...normalize(response.body.data?.meta?.community, communitySchema),
      });
      notifySubmitSucceeded(dispatch, "Recebemos sua solicitação");
    }
  );
};
