import { createSelector } from "reselect";

export const getCurrentUserId = (state) => state.currentUserId;
const getUsers = (state) => state.entities.users;

export const getCurrentUser = createSelector(
  getUsers,
  getCurrentUserId,
  (users, id) => users[id]
);

export const getIsAdmin = createSelector(
  getUsers,
  getCurrentUserId,
  (users, currentUserId) => !!users[currentUserId]?.isAdmin
);

export const isUserLegalAge = createSelector(
  getCurrentUser,
  (user) => user?.isLegalAge
);

export const isUserCbtrgValid = createSelector(
  getCurrentUser,
  (user) => user?.isCbtrgValid
);
