import React from "react";
import { Widget } from "react-typeform-embed";
import { css } from "glamor";
import Spinner from "../library/Spinner";

const container = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "500px",
});

export default function LessonSurvey({
  survey,
  answered,
  currentUserId,
  loading,
}) {
  if (loading) {
    return (
      <div className={container}>
        <Spinner inline />
      </div>
    );
  }

  if (answered) {
    return (
      <div className={container}>
        <h4>Pesquisa respondida</h4>
      </div>
    );
  }

  return survey ? (
    <Widget
      id={survey.code}
      height={500}
      width="100%"
      iframeProps={{ height: "100%", width: "100%" }}
      hidden={{ user_id: currentUserId, survey_id: survey.id }}
    />
  ) : null;
}
