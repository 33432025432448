import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { fetchTerms } from "../../processes/termProcesses";
import { getTerm } from "../../selectors/termSelectors";
import Terms from "./Terms";

function TermsContainer(props) {
  const { term, dispatch, open } = props;
  const [typeDocument, setTypeDocument] = useState("term");
  const showTerm = useCallback(() => {
    setTypeDocument("term");
  }, []);
  const showPrivacyPolicy = useCallback(() => {
    setTypeDocument("privacyPolicy");
  }, []);

  useEffect(() => fetchTerms(dispatch), [dispatch]);

  return open && term ? (
    <Terms
      isTerm={typeDocument === "term"}
      isPrivacyPolicy={typeDocument === "privacyPolicy"}
      showTerm={showTerm}
      showPrivacyPolicy={showPrivacyPolicy}
      open={open}
      {...props}
    />
  ) : null;
}

function mapStateToProps(state) {
  return {
    term: getTerm(state),
  };
}

export default connect(mapStateToProps)(TermsContainer);
