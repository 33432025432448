import { createSelector } from "reselect";
import { getAllClassrooms } from "./classroomSelectors";
import { getFaqCourseIds } from "./faqSelectors";

export const getCourses = createSelector(getAllClassrooms, (classrooms) => [
  ...new Map(
    classrooms.map((classroom) => [
      classroom.courseId,
      { id: classroom.courseId, name: classroom.courseName },
    ])
  ).values(),
]);

export const getCourseIds = createSelector(getCourses, (courses) =>
  courses
    ?.filter((value, index, self) => self.indexOf(value) === index)
    ?.map((course) => course?.id)
);

export const getCoursesWithFaq = createSelector(
  getCourses,
  getFaqCourseIds,
  (courses, ids) => courses.filter((course) => ids.includes(course.id))
);
