import { SubmissionError } from "redux-form";
import { apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { createEnrollment } from "./enrollmentProcesses";
import { setSession } from "./sessionProcesses";

export const user = new schema.Entity("users");

export const signUp = (values, dispatch) => {
  const { classroomId } = values;
  return apiPost("/api/v1/auth")
    .send(values)
    .then((response) => {
      setSession(response);
      dispatch({
        type: "USER_FETCHED",
        ...normalize(response.body.data, user),
      });
      const userId = response.body.data.id;
      dispatch({ type: "CURRENT_USER_FETCHED", id: userId });
      classroomId
        ? createEnrollment(dispatch, classroomId, userId)
        : dispatch(replace("/"));
    })
    .catch((err) => {
      throw new SubmissionError({
        _error: err.response.body.errors.full_messages,
      });
    });
};
