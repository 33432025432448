import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import Button from "../library/Button";
import { css } from "glamor";
import { colors, spacing, styles, uiColors } from "../library/theme";
import Link from "../library/Link";
import ClassroomProgress from "./ClassroomProgress";

const container = css(styles.shadow, styles.roundedCorners, {
  overflow: "hidden",
  "& + &": {
    marginTop: spacing.m,
  },
});
const imageContainer = css({
  height: "100%",
  paddingBottom: "56.25%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
});
const lockedContainer = css({
  ...imageContainer,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 1,
  zIndex: 1,
  background: `${colors.brand}CF`,
  color: colors.white,
  fontWeight: "bold",
  textTransform: "uppercase",
});
const leftColumn = { padding: 0 };
const rightColumn = {
  padding: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  border: `1px solid ${uiColors.border}`,
  borderLeft: "none",
  backgroundColor: uiColors.fieldBackground,
};
const linksWrapper = {
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
const certificateStyle = {
  marginTop: spacing.s,
};

export default function ClassroomListItem({
  classroom,
  progressesHigherThanOne,
  userLegalAge,
  isUserCbtrgValid,
}) {
  const {
    fullName,
    id,
    description,
    meta,
    imageUrl,
    certificateMultipleClassrooms,
  } = classroom;
  const { certificateGenerationDate, compliant, hasEnrollment, isCbtrg } = meta;
  const progressValue = meta?.progress * 100;
  const isStart = Array.isArray(classroom?.meta?.lastLessonUser);
  const invalidEnrollment = hasEnrollment && !compliant;
  const invalidMembership = isCbtrg && !isUserCbtrgValid;
  const isLocked = invalidEnrollment || invalidMembership;

  const iconName = isStart ? "play" : "pause";

  const buttonText = isStart ? "INICIAR" : "CONTINUAR";

  const externalLink = invalidMembership
    ? "https://citrg.com/"
    : "https://www.ibft.com.br";

  const itemUrl = isLocked
    ? { href: externalLink, target: "_blank" }
    : { to: `/classrooms/${id}` };

  return (
    <div className={container}>
      <Grid padded>
        <Grid.Column computer={4} mobile={16} style={leftColumn}>
          <Link {...itemUrl}>
            {isLocked && (
              <div className={lockedContainer}>
                <Icon name="lock" size="big" />
              </div>
            )}
            <div
              className={css(imageContainer, {
                backgroundImage: `url(${imageUrl})`,
              })}
            ></div>
          </Link>
        </Grid.Column>
        <Grid.Column computer={12} mobile={16} style={rightColumn}>
          <Grid columns="equal" padded>
            <Grid.Column computer={11} mobile={16}>
              <h4>{fullName}</h4>
              <p>{description}</p>
            </Grid.Column>
            <Grid.Column
              computer={5}
              mobile={16}
              textAlign="center"
              style={linksWrapper}
            >
              <Link {...itemUrl}>
                <Button primary fluid>
                  <Icon name={isLocked ? "unlock" : iconName} />{" "}
                  {isLocked ? "DESBLOQUEAR" : buttonText}
                </Button>
              </Link>
              {(certificateMultipleClassrooms
                ? progressesHigherThanOne
                : classroom?.hasCertificate &&
                  (!!certificateGenerationDate || progressValue >= 100)) &&
                userLegalAge && (
                  <Link
                    href={`/classrooms/${classroom?.id}/certificates`}
                    target="_blank"
                    size="mini"
                    fluid
                    style={certificateStyle}
                  >
                    Download do Certificado
                  </Link>
                )}
            </Grid.Column>
          </Grid>
          <ClassroomProgress
            value={meta.progress}
            extraLessonsCount={meta.extraLessonsCount}
            coreLessonsCount={meta.coreLessonsCount}
            coreLessonsWatchedCount={meta.coreLessonsWatchedCount}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
}
