import React from "react";
import LessonContentBlocked from "./LessonContentBlocked";
import LessonImage from "./LessonImage";
import LessonVideo from "./LessonVideo";
import LessonSurveyContainer from "./LessonSurveyContainer";

export default function LessonContent(props) {
  const {
    blockContent,
    lesson,
    daysToUnlock,
    watchedPrevious,
    enrollmentCreatedAtDays,
    compliant,
    hasEnrollment,
    isCbtrg,
    isUserCbtrgValid,
    isUnlocked,
  } = props;
  const invalidEnrollment = hasEnrollment && !compliant;
  const invalidMembership = isCbtrg && !isUserCbtrgValid;

  if (blockContent || invalidEnrollment || invalidMembership || !isUnlocked) {
    return (
      <LessonContentBlocked
        daysToUnlock={daysToUnlock}
        watchedPrevious={watchedPrevious}
        enrollmentCreatedAtDays={enrollmentCreatedAtDays}
        compliant={compliant}
        hasEnrollment={hasEnrollment}
        invalidMembership={invalidMembership}
        isUnlocked={isUnlocked}
      />
    );
  }

  if (lesson?.lessonType == "survey") {
    return <LessonSurveyContainer />;
  }

  if (lesson?.lessonType == "video") {
    return <LessonVideo key={`lesson${lesson?.id}`} {...props} />;
  }

  if (lesson?.lessonType == "image") {
    return <LessonImage image={lesson?.imageUrl} />;
  }

  return null;
}
