import React from "react";
import { Form, Segment, Message } from "semantic-ui-react";

export default function FormComponent(props) {
  const { children, style, error, ...rest } = props;
  return (
    <Segment textAlign="left" padded stacked style={style}>
      {error && (
        <Message
          header={error ? "Oops, algo deu errado!" : undefined}
          list={error}
          error
        />
      )}
      <Form {...rest} error={!!error}>
        {children}
      </Form>
    </Segment>
  );
}
