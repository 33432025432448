import React from "react";
import { Route, Switch } from "react-router-dom";
import Lobby from "../components/Lobby";
import SignInFormContainer from "../components/signIn/SignInFormContainer";
import RecoveryPasswordPage from "../components/recoveryPassword/RecoveryPasswordPage";
import NewPasswordPage from "../components/recoveryPassword/NewPasswordPage";
import RequireSignIn from "../components/lobby/RequireSignIn";

export default function LobbyRoutes(props) {
  return (
    <Lobby {...props}>
      <Switch>
        <Route path="/signin" component={SignInFormContainer} exact />
        <Route
          path="/forgotten-password"
          component={RecoveryPasswordPage}
          exact
        />
        <Route path="/reset-password" component={NewPasswordPage} exact />
        <Route path="*" component={RequireSignIn} />
      </Switch>
    </Lobby>
  );
}
