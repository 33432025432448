import React, { useCallback } from "react";
import { connect } from "react-redux";
import PostFormModal from "./PostFormModal";
import { replace } from "connected-react-router";

function PostFormModalContainer({ dispatch, communityId }) {
  const closeHandler = useCallback(() => {
    dispatch(replace(`/communities/${communityId}`));
  }, [communityId]);

  return (
    <PostFormModal communityId={communityId} closeHandler={closeHandler} />
  );
}

function mapStateToProps(state, ownProps) {
  const { communityId } = ownProps.match.params;

  return {
    communityId,
  };
}

export default connect(mapStateToProps)(PostFormModalContainer);
