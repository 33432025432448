import { createSelector } from "reselect";
import { orderByDateDesc } from "../utils/orderByDate";
import { getCurrentUserId } from "./userSelectors";

const getLikes = (state) => state.entities.likes;
const getId = (_, id) => id;
const getProps = (_, props) => props;

export const getAllLikes = createSelector(getLikes, (likes) =>
  Object.values(likes)
);

export const getLikesByLikeable = createSelector(
  getAllLikes,
  getProps,
  (likes, { likeableType, likeableId }) => {
    return likes
      .filter((like) => {
        return (
          like.likeableType == likeableType && like.likeableId == likeableId
        );
      })
      .sort((a, b) => orderByDateDesc(a.createdAt, b.createdAt));
  }
);

export const getLikesByType = createSelector(
  getLikesByLikeable,
  getProps,
  (likes, { type }) => {
    return likes
      .filter((like) => like.type == type)
      .sort((a, b) => orderByDateDesc(a.createdAt, b.createdAt));
  }
);

export const getLikeById = createSelector(
  getLikes,
  getId,
  (likes, id) => like[id]
);

export const getLike = createSelector(
  getAllLikes,
  getProps,
  getCurrentUserId,
  (likes, { likeableType, likeableId }, userId) => {
    return likes.find((like) => {
      return (
        like.likeableType == likeableType &&
        like.likeableId == likeableId &&
        like.userId == userId
      );
    });
  }
);
