import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createPost } from "../../processes/postProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import buildForm from "../library/forms/buildForm";
import PostForm from "./PostForm";
import { formValueSelector, change } from "redux-form";
import truncateString from "../../utils/truncateString";
import {
  getAllPostCategories,
  getPostCategoryById,
} from "../../selectors/postCategorySelectors";

const FORM_NAME = "createPostForm";
const form = buildForm(FORM_NAME);

function PostFormContainer(props) {
  const { dispatch, submitSucceeded, postCategory, text, closeHandler, user } =
    props;

  useEffect(() => {
    if (text?.length > postCategory?.maxLength && !user?.isAdmin) {
      dispatch(
        change(
          FORM_NAME,
          "text",
          truncateString(text, postCategory?.maxLength - 3)
        )
      );
    }
  }, [dispatch, user?.isAdmin, text, postCategory]);

  useEffect(() => {
    if (submitSucceeded) {
      closeHandler();
    }
  }, [submitSucceeded]);

  return <PostForm closeHandler={closeHandler} {...props} />;
}

function mapStateToProps(state, { communityId }) {
  const user = getCurrentUser(state);
  const postCategories = getAllPostCategories(state);
  const postValueSelector = formValueSelector(FORM_NAME);
  const { text, postCategoryId, imageUrl } = postValueSelector(
    state,
    "text",
    "postCategoryId",
    "imageUrl"
  );

  return {
    initialValues: {
      userId: user?.id,
      communityId,
      postCategoryId: `${postCategories[0]?.id}`,
    },
    imageUrl,
    user,
    text,
    postCategories,
    postCategory: getPostCategoryById(state, postCategoryId),
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createPost,
    enableReinitialize: true,
  })(PostFormContainer)
);
