import { createSelector } from "reselect";

const getSurveyUsers = (state) => state.entities.surveysUsers;
const getSurveyId = (_, id) => id;

export const getAllSurveyUsers = createSelector(
  getSurveyUsers,
  (surveysUsers) => Object.values(surveysUsers)
);

export const makeAnsweredSurvey = createSelector(
  getAllSurveyUsers,
  getSurveyId,
  (surveyUsers, surveyId) =>
    surveyUsers.some((surveyUser) => surveyUser.surveyId == surveyId)
);
