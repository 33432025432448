import { createSelector } from "reselect";

const getConfigs = (state) => state.entities.configs;
const getConfigName = (_, name) => name;
const getStateId = (_, id) => id;
const getIds = (_, ids) => ids;

export const getConfig = createSelector(
  getConfigs,
  getConfigName,
  (configs, name) => {
    const config = configs[name];
    return config && Object.values(config);
  }
);

export const getCitiesByStateId = createSelector(
  getConfigs,
  getStateId,
  (configs, stateId) =>
    Object.values(configs["cities"])?.filter(
      (city) => city?.stateId === stateId
    )
);

export const getBadgesByIds = createSelector(
  getConfigs,
  getIds,
  (configs, ids = []) =>
    Object.values(configs["badges"])?.filter(({ id }) => ids.includes(id))
);

export const featureEnabled = createSelector(
  getConfigs,
  getConfigName,
  (configs, name) => {
    if (!configs?.betaFeatures) return;

    return configs?.betaFeatures[name];
  }
);
