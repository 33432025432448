import { createSelector } from "reselect";
import orderByPosition from "./helpers/orderByPosition";

const getLevelGroups = (state) => state.entities.levelGroups;
const getCurrentClassroomId = (state) => state.currentClassroomId;
export const getLevelGroup = (_, props) => props.levelGroup;

export const getLevelGroupsByClassroom = createSelector(
  getCurrentClassroomId,
  getLevelGroups,
  (classroomId, levelGroups) => {
    if (!levelGroups) return;
    return Object.values(levelGroups)
      .filter((levelGroup) =>
        levelGroup.classroomIds.includes(Number(classroomId))
      )
      .sort(orderByPosition);
  }
);
