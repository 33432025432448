import React from "react";
import { Button, Grid, Icon, Modal } from "semantic-ui-react";
import Link from "../library/Link";

export default function SupportPage() {
  return (
    <Modal open>
      <Modal.Header>
        <h2>Suporte</h2>
        <p>Utilize o canal abaixo para obter ajuda da nossa equipe.</p>
      </Modal.Header>
      <Modal.Content>
        <Grid
          columns="equal"
          celled="internally"
          verticalAlign="middle"
          centered
          padded
          doubling
          stackable
        >
          <Grid.Column textAlign="center">
            <h5>Entre em contato através do nosso whatsapp:</h5>
            <Link
              href="https://wa.me/5581981785519"
              component={Button}
              target="_blank"
            >
              <Icon name="whatsapp"></Icon> +55 81 9 8178-5519
            </Link>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Link to="/" component={Button}>
          Fechar
        </Link>
      </Modal.Actions>
    </Modal>
  );
}
