const getPostCategories = (action) => action.entities.postCategories;

export default function postCategoriesReducer(state = {}, action) {
  switch (action.type) {
    case "POST_CATEGORIES_FETCHED":
      return { ...state, ...getPostCategories(action) };
    default:
      return state;
  }
}
