import React, { useCallback } from "react";
import { connect, useSelector } from "react-redux";

import { signOut } from "../../processes/sessionProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import Header from "./Header";

function HeaderContainer(props) {
  const { dispatch } = props;
  const signOutHandler = useCallback(() => signOut(dispatch), [dispatch]);
  const currentUser = useSelector((state) => getCurrentUser(state));

  return (
    <Header
      {...props}
      currentUser={currentUser}
      signOutHandler={signOutHandler}
    />
  );
}

export default connect(undefined)(HeaderContainer);
