import { normalize, schema } from "normalizr";
import { apiGet, apiPost, apiDel, apiPut } from "./helpers/api";
import { commentSchema } from "./commentProcesses";
import { likeSchema } from "./likeProcesses";
import { notifySubmitFail } from "./notifierProcesses";
import { put } from "superagent";
import middleware from "./helpers/railsMiddleware";

const postSchema = new schema.Entity("posts", {
  meta: {
    comments: [commentSchema],
    likes: [likeSchema],
  },
});

export const fetchPosts = (dispatch, communityId) => {
  return apiGet(`/api/v1/communities/${communityId}/posts`).then((response) => {
    dispatch({
      type: "POSTS_FETCHED",
      ...normalize(response.body.data, [postSchema]),
    });
  });
};

const isFile = (obj) => obj && !!obj.lastModified;

export const createPost = (post, dispatch) => {
  const { imageUrl, ...attrs } = post;

  return apiPost(`/api/v1/communities/${post?.communityId}/posts`)
    .send(attrs)
    .then((response) => {
      if (isFile(imageUrl)) {
        const req = put(`/api/v1/posts/${response?.body?.data?.id}`).use(
          middleware
        );
        req.attach("post[image]", imageUrl);
        return req.then((response) => {
          dispatch({
            type: "POST_CREATED",
            ...normalize(response.body.data, postSchema),
          });
        });
      } else {
        dispatch({
          type: "POST_CREATED",
          ...normalize(response?.body?.data, postSchema),
        });
      }
    });
};

export const deletePost = (dispatch, { id, communityId }) => {
  return apiDel(`/api/v1/communities/${communityId}/posts/${id}`).then(
    (response) => {
      dispatch({ type: "POST_DELETED", id });
    }
  );
};

export const toggleCommentsEnabled = (dispatch, { id, commentsEnabled }) => {
  return apiPut(`/api/v1/posts/${id}`)
    .send({ commentsEnabled: !commentsEnabled })
    .then((response) => {
      dispatch({
        type: "POST_UPDATED",
        ...normalize(response.body.data, postSchema),
      });
    });
};

export const togglePinPost = (dispatch, { postId, pinnedPostIds = [] }) => {
  const isPinned = pinnedPostIds.includes(postId);
  const pinnedPosition = isPinned ? null : pinnedPostIds.length + 1;

  dispatch({ type: "POST_PINNED_POSITION_CHANGED", postId, pinnedPosition });
  return updatePost(dispatch, postId, { pinnedPosition });
};

export const swapPinnedPostOrder = (
  dispatch,
  { postId, pinnedPostIds = [] }
) => {
  const isPinned = pinnedPostIds.includes(postId);
  const isLast = pinnedPostIds.indexOf(postId) == pinnedPostIds.length;

  if ((isPinned && isLast) || !isPinned) return;

  const upperId = pinnedPostIds[pinnedPostIds.indexOf(postId) + 1];
  const upperPosition = pinnedPostIds.indexOf(upperId) + 1;

  dispatch({
    type: "POST_PINNED_POSITION_CHANGED",
    postId,
    pinnedPosition: upperPosition,
  });
  updatePost(dispatch, postId, {
    pinnedPosition: upperPosition,
  });

  dispatch({
    type: "POST_PINNED_POSITION_CHANGED",
    postId: upperId,
    pinnedPosition: upperPosition - 1,
  });
  updatePost(dispatch, upperId, {
    pinnedPosition: upperPosition - 1,
  });
};

export const updatePost = (dispatch, postId, values) => {
  if (!postId) return;
  return apiPut(`/api/v1/posts/${postId}`)
    .send(values)
    .then((response) => {
      dispatch({
        type: "POST_UPDATED",
        ...normalize(response.body.data, postSchema),
      });
    })
    .catch((err) => {
      notifySubmitFail(dispatch, "Não conseguimos salvar sua ação...");
    });
};
