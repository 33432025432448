import { useEffect } from "react";

export const useFragmentRouting = ({ hash }, dependencies = []) => {
  useEffect(() => {
    if (hash) {
      const anchor = hash.replace("#", "");
      const el = document.getElementById(anchor);
      el?.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash, ...dependencies]);
};
