import React from "react";
import {
  Tab,
  Modal,
  spacing,
  typographyColors,
  uiColors,
  decorationColors,
} from "../library";
import { css } from "glamor";
import { IoIosClose } from "react-icons/io";
import { BiLike, BiHeart, BiMedal } from "react-icons/bi";
import { MdOutlineSignLanguage } from "react-icons/md";
import { fromNow } from "../../utils/formatDate";
import LikeIcon from "./LikeIcon";

const triggerClass = css({
  ":hover": {
    cursor: "pointer",
    color: decorationColors.decorationPurple,
  },
});
const content = css({
  padding: `${spacing.s} ${spacing.m}`,
});
const headerClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
const closeClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: spacing.xs,
  marginRight: `-${spacing.s}`,
  borderRadius: "50%",
  color: uiColors.border,
  cursor: "pointer",
  ":hover": {
    color: typographyColors.defaultBody,
    backgroundColor: "#00000020",
  },
});
const titleClass = css({
  fontWeight: "bolder",
  color: typographyColors.defaultBody,
});
const authorClass = css({
  color: typographyColors.defaultTitle,
});
const authorsGroupWrapper = css({
  height: 200,
  overflow: "auto",
  padding: `0 ${spacing.s}`,
});
const authorWrapper = css({
  flex: 1,
  display: "flex",
  gap: spacing.s,
  "& + &": {
    marginTop: spacing.s,
  },
});
const iconContainerClass = css({
  display: "flex",
  "> *": {
    margin: "0px 3px 0px 0px",
  },
  "> *:not(:first-child)": {
    marginLeft: "-8px",
  },
});
const authorNameClass = css({ fontWeight: 600 });
const createdAtClass = css({ display: "block", lineHeight: 1 });
const avatarStyle = { height: "40px", width: "40px", borderRadius: "50%" };
const iconStyle = { marginRight: spacing.xxs };
const menuOptions = {
  secondary: true,
  pointing: true,
  stackable: true,
};

const getPanes = (options) => {
  return options.map(({ key, content, icon, likes }) => {
    return {
      key,
      menuItem: { key, icon, content, disabled: !likes.length },
      render: () => (
        <div className={authorsGroupWrapper}>
          {likes.map((like) => (
            <div className={authorWrapper} key={like.userId}>
              <img src={like?.meta?.user?.avatarUrl} style={avatarStyle} />
              <div className={authorClass}>
                <span className={authorNameClass}>
                  {like?.meta?.user?.name}
                </span>
                <span className={createdAtClass}>
                  {fromNow(like.createdAt)}
                </span>
              </div>
            </div>
          ))}
        </div>
      ),
    };
  });
};

export default function LikeModal({
  open,
  toggleHandler,
  all,
  defaults,
  congrats,
  loves,
  awesomes,
  showIcons,
}) {
  const [activeIdx, setActiveIdx] = React.useState(0);
  const paneOptions = [
    {
      key: "all",
      icon: null,
      content: `Todas ${all.length}`,
      likes: all,
    },
    {
      key: "default",
      icon: <BiLike size={16} style={iconStyle} />,
      content: defaults.length,
      likes: defaults,
    },
    {
      key: "congrats",
      icon: <MdOutlineSignLanguage size={16} style={iconStyle} />,
      content: congrats.length,
      likes: congrats,
    },
    {
      key: "love",
      icon: <BiHeart size={16} style={iconStyle} />,
      content: loves.length,
      likes: loves,
    },
    {
      key: "awesome",
      icon: <BiMedal size={16} style={iconStyle} />,
      content: awesomes.length,
      likes: awesomes,
    },
  ];

  const colors = [
    "violet",
    decorationColors.decorationGreen,
    decorationColors.decorationOrange,
    decorationColors.decorationRed,
    decorationColors.decorationPurple,
  ];
  const getColorForTab = (key) => {
    const index = paneOptions.findIndex((option) => option.key === key);
    return colors[index];
  };
  const panes = getPanes(paneOptions);
  const paneContent = panes.map((pane) => ({
    ...pane,
    menuItem: {
      ...pane.menuItem,
      style: {
        color: getColorForTab(pane.key),
      },
    },
  }));

  return (
    <>
      {showIcons && (
        <div className={iconContainerClass}>
          {defaults?.length > 0 && (
            <LikeIcon
              icon={BiLike}
              color={decorationColors?.decorationGreen}
              zIndex={4}
            />
          )}
          {congrats?.length > 0 && (
            <LikeIcon
              icon={MdOutlineSignLanguage}
              color={decorationColors?.decorationOrange}
              zIndex={3}
            />
          )}
          {loves?.length > 0 && (
            <LikeIcon
              icon={BiHeart}
              color={decorationColors?.decorationRed}
              zIndex={2}
            />
          )}
          {awesomes.length > 0 && (
            <LikeIcon
              icon={BiMedal}
              color={decorationColors?.decorationPurple}
              zIndex={1}
            />
          )}
        </div>
      )}
      <div className={triggerClass} onClick={toggleHandler}>
        {all?.length} {all?.length > 1 ? "Curtidas" : "Curtida"}
      </div>
      <Modal
        open={!!all?.length && open}
        onClose={toggleHandler}
        size="mini"
        closeOnDimmerClick={false}
        content={
          <div className={content}>
            <div className={headerClass}>
              <div className={titleClass}>Reações</div>
              <div onClick={toggleHandler} className={closeClass}>
                <IoIosClose size={22} />
              </div>
            </div>

            <Tab
              className={css({
                ">div>.active.item": {
                  color: `${getColorForTab(activeIdx)} !important`,
                  borderColor: "#7DAFF4 !important",
                },
              })}
              onTabChange={(_, { activeIndex }) => setActiveIdx(activeIndex)}
              menu={menuOptions}
              panes={paneContent}
            />
          </div>
        }
      ></Modal>
    </>
  );
}
