import React from "react";
import {
  Form,
  DropDownField,
  Button,
  TextAreaField,
  spacing,
} from "../library";

const statusOptions = [
  { text: "Aprovado", value: "enabled" },
  { text: "Pendente", value: "pending" },
  { text: "Rejeitado", value: "disabled" },
];

const buttonStyle = {
  display: "block",
  marginTop: spacing.m,
  marginLeft: "auto",
};

export default function CommentModerationForm({
  handleSubmit,
  submitting,
  invalid,
  pristine,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <DropDownField
        name="status"
        label="Status"
        options={statusOptions}
        required
        selection
        fluid
      />
      <TextAreaField
        name="justificationNote"
        label="Justifique sua alteração"
        placeholder="Escreva aqui..."
        required
        rows={5}
      />
      <Button
        color="orange"
        disabled={submitting || invalid || pristine}
        style={buttonStyle}
      >
        Moderar
      </Button>
    </Form>
  );
}
