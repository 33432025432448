import React, { useCallback } from "react";
import Page from "../library/Page";
import ClassroomListItem from "./ClassroomListItem";
import EmptyClassrooms from "./EmptyClassrooms";
import Spinner from "../library/Spinner";
import BannersContainer from "../banner/BannersContainer";
import { css } from "glamor";
import { spacing } from "../library/theme";

const loadingClass = css({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: `${spacing.m} 0`,
});
const bannerClass = css({ marginBottom: spacing.m });

export default function Classrooms({
  classrooms,
  loading,
  userLegalAge,
  isUserCbtrgValid,
}) {
  const progressesHigherThanOne = useCallback((certificateId) => {
    const classroomsWithSameCertificate = classrooms.filter(
      (classroom) => classroom.certificateId == certificateId
    );
    return classroomsWithSameCertificate.every(
      (classroom) => classroom.meta.progress >= 1
    );
  });

  return (
    <Page title="Meus Cursos">
      <BannersContainer bannerType="home" className={bannerClass} />
      <div>
        {loading ? (
          <div className={loadingClass}>
            <Spinner inline text="Carregando cursos..." />
          </div>
        ) : classrooms.length ? (
          classrooms.map((classroom, index) => (
            <ClassroomListItem
              key={index}
              classroom={classroom}
              progressesHigherThanOne={progressesHigherThanOne(
                classroom.certificateId
              )}
              userLegalAge={userLegalAge}
              isUserCbtrgValid={isUserCbtrgValid}
            />
          ))
        ) : (
          <EmptyClassrooms />
        )}
      </div>
    </Page>
  );
}
