import { connect } from "react-redux";
import { getFaqByCourse } from "../../selectors/faqSelectors";
import FaqCourse from "./FaqAccordion";

const mapStateToProps = (state, ownProps) => {
  const { course } = ownProps;

  return {
    faqs: getFaqByCourse(state, course?.id),
  };
};

export default connect(mapStateToProps)(FaqCourse);
