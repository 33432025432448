import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const stateSchema = new schema.Entity("states");
const citySchema = new schema.Entity("cities");
const badgeSchema = new schema.Entity("badges");
const betaFeatureSchema = new schema.Entity("betaFeatures");

const valuesSchema = new schema.Values(
  {
    states: new schema.Array(stateSchema),
    cities: new schema.Array(citySchema),
    badges: new schema.Array(badgeSchema),
    betaFeatures: new schema.Array(betaFeatureSchema),
  },
  (input, parent, key) => `${key}`
);

export const fetchConfigs = (dispatch) => {
  apiGet("/api/v1/config").then((response) => {
    const data = response.body.data[0];
    dispatch({
      type: "CONFIGS_FETCHED",
      ...{ ...normalize(data, valuesSchema), betaFeatures: data.betaFeatures },
    });
  });
};

export const fetchCities = (dispatch, stateId) => {
  apiGet(`/api/v1/states/${stateId}/cities`).then((response) => {
    dispatch({
      type: "CITIES_FETCHED",
      ...normalize({ cities: response.body.data }, valuesSchema),
    });
  });
};
