const getComments = (action) => action.entities.comments;

export default function commentsReducer(state = {}, action) {
  switch (action.type) {
    case "POSTS_FETCHED":
    case "COMMENT_CREATED":
    case "COMMENT_UPDATED":
      return { ...state, ...getComments(action) };
    case "COMMENT_DELETED":
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
