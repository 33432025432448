import { createSelector } from "reselect";
import { orderByDateDesc } from "../utils/orderByDate";
import { getCurrentUser } from "./userSelectors";

const getPosts = (state) => state.entities.posts;
const getId = (_, id) => id;

const orderByCreatedAt = (a, b) => orderByDateDesc(a.createdAt, b.createdAt);
const orderByPinnedPositionAsc = (a, b) => a.pinnedPosition - b.pinnedPosition;
const orderByPinnedPositionDesc = (a, b) => b.pinnedPosition - a.pinnedPosition;

export const getAllPosts = createSelector(getPosts, (posts) =>
  Object.values(posts)
);

export const getCommunityPosts = createSelector(
  getAllPosts,
  getId,
  getCurrentUser,
  (posts, communityId, user) => {
    return posts
      .filter(
        (post) =>
          post.communityId == communityId &&
          (post.status == "approved" ||
            post.userId == user?.id ||
            (user?.isAdmin && post.status == "pending"))
      )
      .sort(orderByCreatedAt)
      .sort(orderByPinnedPositionDesc);
  }
);

export const getPostById = createSelector(getPosts, getId, (posts, id) => {
  return posts[id];
});

export const getPinnedPostIds = createSelector(getCommunityPosts, (posts) => {
  return posts
    .filter(({ pinnedPosition }) => pinnedPosition != null)
    .sort(orderByPinnedPositionAsc)
    .map(({ id }) => id);
});
