const initialState = {
  classroomIds: [],
  loading: false,
  searchLessonIds: [],
  searchLoading: false,
};

const getClassroomsFetchedIds = (action) =>
  action.result ? action.result : initialState.classroomIds;

export default function classroomsListReducer(state = initialState, action) {
  switch (action.type) {
    case "CLASSROOM_LESSON_SEARCH_FETCH_REQUEST":
      return { ...state, searchLoading: true };
    case "CLASSROOM_LESSON_SEARCH_FETCHED":
      return { ...state, searchLessonIds: action.result, searchLoading: false };
    case "CLASSROOMS_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "CLASSROOMS_FETCHED":
      return {
        ...state,
        classroomIds: getClassroomsFetchedIds(action),
        loading: false,
      };
    default:
      return state;
  }
}
