import { replace } from "connected-react-router";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { Sidebar } from "semantic-ui-react";
import { apoloProcess } from "../processes/apoloProcesses";
import { fetchBanners } from "../processes/bannerProcesses";
import { fetchClassrooms } from "../processes/classroomProcesses";
import { fetchCommunities } from "../processes/communityProcesses";
import { fetchConfigs } from "../processes/configProccess";
import { fetchInvoices } from "../processes/invoiceProcesses";
import { fetchLessons } from "../processes/lessonProcesses";
import { fetchLevelGroups } from "../processes/levelGroupProcesses";
import { fetchLevels } from "../processes/levelProcesses";
import { fetchModals } from "../processes/modalProcesses";
import { fetchRatings } from "../processes/ratingProcesses";
import { fetchPostCategories } from "../processes/postCategoryProcesses";
import { sessionValidate } from "../processes/sessionProcesses";
import { getCurrentUser } from "../selectors/userSelectors";
import HeaderContainer from "./header/HeaderContainer";
import { uiColors, utilityColors } from "./library/theme";
import MainMenuContainer from "./mainMenu/MainMenuContainer";
import ModalContainer from "./modal/ModalContainer";
import NotifierContainer from "./notifier/NotifierContainer";
import { css } from "glamor";

const desktopMenuWrapper = css({
  "@media(max-width: 991px)": { display: "none" },
});
const content = { display: "flex" };
const rightColumn = {
  backgroundColor: uiColors.contentCards,
  overflow: "auto",
  padding: 0,
  paddingTop: 70,
  minHeight: "100vh",
  flex: 1,
};
const sidebarStyle = {
  padding: 0,
  boxShadow: `0 2px 5px ${utilityColors.shadow}`,
};

function LMS(props) {
  const { dispatch, location, currentClassroomId, currentUser } = props;
  const currentPath = location.pathname;
  const isLessonPath = !!matchPath(currentPath, {
    path: "/classrooms/:id/lessons",
  });
  const isProfileEditPath = !!matchPath(currentPath, {
    path: "/profile/edit",
  });
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);
  const hideSidebar = useCallback(() => setShowSidebar(false));
  const onShowMobileSidebar = useCallback(
    () => (document.documentElement.scrollTop = 0)
  );

  useEffect(() => {
    apoloProcess({ email: currentUser?.email }).finally(() => {
      fetchClassrooms(dispatch);
    });
  }, [dispatch, currentUser?.email]);

  useEffect(() => {
    sessionValidate(dispatch);
    fetchConfigs(dispatch);
    fetchModals(dispatch);
    fetchRatings(dispatch);
    fetchBanners(dispatch);
    fetchInvoices(dispatch);
    fetchCommunities(dispatch);
    fetchPostCategories(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (currentClassroomId) {
      fetchLevelGroups(dispatch, currentClassroomId);
      fetchLevels(dispatch, currentClassroomId);
      fetchLessons(dispatch, currentClassroomId);
    }
  }, [dispatch, currentClassroomId]);

  useEffect(() => {
    if (currentUser && !currentUser?.updated && !isProfileEditPath) {
      dispatch(replace("/profile/edit"));
    }
  }, [dispatch, currentUser, isProfileEditPath]);

  return (
    <div>
      <ModalContainer />
      <Sidebar.Pushable>
        <Sidebar
          animation="push"
          icon="labeled"
          visible={showSidebar}
          onHide={hideSidebar}
          onShow={onShowMobileSidebar}
          style={sidebarStyle}
        >
          <MainMenuContainer {...props} mobile />
        </Sidebar>
        <Sidebar.Pusher dimmed={showSidebar}>
          <HeaderContainer
            toggleSidebar={toggleSidebar}
            isLessonPath={isLessonPath}
          />
          <div style={content}>
            {!isLessonPath && currentUser?.updated && (
              <div className={desktopMenuWrapper}>
                <MainMenuContainer {...props} />
              </div>
            )}
            <div style={rightColumn}>
              <NotifierContainer />
              {props.children}
            </div>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currentClassroomId: state.currentClassroomId,
    currentUser: getCurrentUser(state),
  };
}

export default connect(mapStateToProps)(LMS);
