import { createSelector } from "reselect";
import { getAllLevels, getLevel, getLevels } from "./levelSelectors";
import {
  getCurrentClassroomId,
  getCurrentClassroom,
} from "./classroomSelectors";
import orderByPosition from "./helpers/orderByPosition";
import { getLevelGroupsByClassroom } from "./levelGroupSelectors";
import { getIsAdmin } from "./userSelectors";

const getLessons = (state) => state.entities.lessons;
export const lessonsLoading = (state) => state.lessonsList.loading;
export const getLessonId = (_, id) => id;
export const getCurrentLessonId = (state) => state.currentLessonId;
export const getIds = (_, ids) => ids;

export const getCurrentLesson = createSelector(
  getLessons,
  getLessonId,
  (lessons, id) => lessons[id]
);

export const getLessonsByLevel = createSelector(
  getLevel,
  getLessons,
  (level, lessons) =>
    Object.values(lessons)
      .filter((lesson) => lesson.levelId === level.id)
      .sort(orderByPosition)
);

export const getCurrentLevel = createSelector(
  getLevels,
  getLessons,
  getCurrentLessonId,
  (levels, lessons, lessonId) => {
    const currentLesson = lessons[lessonId];
    return levels[currentLesson?.levelId];
  }
);

export const listOfLessons = createSelector(
  getLessons,
  getCurrentClassroomId,
  (lessons, classroomId) =>
    Object.values(lessons)
      .filter((lesson) => lesson.classroomIds.includes(Number(classroomId)))
      .sort(orderByPosition)
);

export const orderedListOfLessons = createSelector(
  getLevelGroupsByClassroom,
  getAllLevels,
  listOfLessons,
  (levelGroups, levels, lessons) => {
    const allLessonsOrdered = [];
    levelGroups?.forEach((levelGroup) => {
      levels
        ?.filter((level) => level?.levelGroupId === levelGroup?.id)
        .sort(orderByPosition)
        .forEach((level) => {
          lessons
            ?.filter((lesson) => lesson?.levelId === level?.id)
            .forEach((lesson) => allLessonsOrdered.push(lesson));
        });
    });
    return allLessonsOrdered;
  }
);

export const getNextNonWatchedLessonId = createSelector(
  orderedListOfLessons,
  (lessons) => lessons.find((lesson) => !lesson.watched)?.id
);

const nextOrPrevLesson = (lessons, currentId, value = 1) => {
  const lesson = lessons.filter((lesson) => lesson.id === currentId)[0];
  const idx = lessons?.indexOf(lesson);
  return lessons[idx + value];
};

export const getNextLesson = createSelector(
  orderedListOfLessons,
  getCurrentLessonId,
  (lessons, currentId) => nextOrPrevLesson(lessons, currentId)
);

export const getPrevLesson = createSelector(
  orderedListOfLessons,
  getCurrentLessonId,
  (lessons, currentId) => nextOrPrevLesson(lessons, currentId, -1)
);

export const isPreviousLessonsWatched = createSelector(
  orderedListOfLessons,
  getCurrentLesson,
  (lessons, currentLesson) =>
    !lessons
      .slice(0, lessons.indexOf(currentLesson))
      .filter((lesson) => lesson.core)
      .some((lesson) => !lesson.watched)
);

export const isLessonUnlocked = createSelector(
  getCurrentClassroom,
  getCurrentLesson,
  (currentClassroom, currentLesson) =>
    currentClassroom?.enrollmentCreatedAtDays >= currentLesson?.daysToUnlock ||
    currentClassroom?.enrollmentCreatedAtDays === null ||
    currentLesson?.daysToUnlock === null
);

export const blockContent = createSelector(
  isPreviousLessonsWatched,
  isLessonUnlocked,
  getIsAdmin,
  (isPreviousLessonsWatched, isLessonUnlocked, isAdmin) => {
    if (isAdmin) return false;
    return !(isPreviousLessonsWatched && isLessonUnlocked);
  }
);

export const getAllLessons = createSelector(getLessons, (lessons) =>
  lessons ? Object.values(lessons) : []
);

export const getLessonsByIds = createSelector(
  getAllLessons,
  getIds,
  (lessons, ids) => lessons.filter((lesson) => ids.includes(lesson.id))
);
