import React from "react";
import { Modal } from "semantic-ui-react";
import Button from "../library/Button";
import { css } from "glamor";

const contentClass = css({
  height: "70vh",
  width: "100%",
});

export default function Terms({
  term,
  open,
  onClose,
  isTerm,
  isPrivacyPolicy,
  showPrivacyPolicy,
  showTerm,
}) {
  return (
    <Modal open={open} onClose={onClose} closeOnDimmerClick>
      <Modal.Header>
        {isTerm && "Termos de Uso"}
        {isPrivacyPolicy && "Políticas de Privacidade"}
      </Modal.Header>
      {isTerm && (
        <iframe
          className={contentClass}
          seamless
          sandbox
          srcdoc={`<div style="white-space: pre-line">${term?.content}</div>`}
        />
      )}
      {isPrivacyPolicy && (
        <iframe
          className={contentClass}
          seamless
          sandbox
          srcdoc={`<div style="white-space: pre-line">${term?.privacyPolicyContent}</div>`}
        />
      )}
      <Modal.Actions>
        {!isTerm && (
          <Button onClick={showTerm} primary>
            Ler Termos de uso
          </Button>
        )}
        {!isPrivacyPolicy && term?.privacyPolicyContent && (
          <Button onClick={showPrivacyPolicy} primary>
            Ler Políticas de Privacidade
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}
