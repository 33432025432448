import React from "react";
import { connect } from "react-redux";
import LessonListItem from "./LessonListItem";

function LessonListItemContainer(props) {
  return <LessonListItem {...props} />;
}

function mapStateToProps(state, ownProps) {
  return {
    lesson: ownProps.lesson,
    currentClassroomId: state.currentClassroomId,
    currentLessonId: state.currentLessonId,
  };
}

export default connect(mapStateToProps)(LessonListItemContainer);
