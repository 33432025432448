import React, { useCallback } from "react";
import { connect } from "react-redux";
import PostComplaintFormModal from "./PostComplaintFormModal";
import { replace } from "connected-react-router";

function PostComplaintFormModalContainer({ dispatch, communityId, postId }) {
  const closeHandler = useCallback(() => {
    dispatch(replace(`/communities/${communityId}`));
  }, [communityId]);

  return (
    <PostComplaintFormModal
      communityId={communityId}
      postId={postId}
      closeHandler={closeHandler}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { communityId, postId } = ownProps.match.params;

  return {
    communityId,
    postId,
  };
}

export default connect(mapStateToProps)(PostComplaintFormModalContainer);
