import moment from "moment";
moment.locale("pt-BR");

export default function formatDate(date, format = "") {
  return date ? moment(date).format(format) : "";
}

export function isYearOld(date) {
  moment(date).add(1, "year");
  return date ? isBeforeToday(date) : false;
}

export const newDate = (string, format = "") => moment(string, format);

export const fromNow = (date) => moment(date).fromNow();

export const isAfterToday = (date) => new Date(date) >= new Date();

export const isBeforeToday = (date) => new Date(date) < new Date();
