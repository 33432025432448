import { createSelector } from "reselect";

const getPostCategories = (state) => state.entities.postCategories;
const getId = (_, id) => id;

export const getAllPostCategories = createSelector(getPostCategories, (posts) =>
  Object.values(posts)
);

export const getPostCategoryById = createSelector(
  getPostCategories,
  getId,
  (postCategories, id) => postCategories[id]
);
