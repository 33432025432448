import { createSelector } from "reselect";

const getModals = (state) => state.entities.modals;
const getProp = (_, prop) => prop;

export const getAllModals = createSelector(getModals, (modals) =>
  Object.values(modals)
);

export const getModalForHome = createSelector(
  getAllModals,
  (modals) =>
    modals?.filter(
      (modal) =>
        !modal?.closed &&
        (modal?.modalCategory == "classroom" ||
          modal?.modalCategory == "course")
    )[0]
);

export const getModalForCommunity = createSelector(
  getAllModals,
  getProp,
  (modals, communityId) =>
    modals?.filter(
      (modal) =>
        !modal?.closed &&
        modal?.modalCategory == "community" &&
        (modal?.meta?.communityIds?.includes(communityId) ||
          !modal?.meta?.communityIds?.length)
    )[0]
);
