import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createPostComplaint } from "../../processes/postComplaintProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import buildForm from "../library/forms/buildForm";
import PostComplaintForm from "./PostComplaintForm";

const FORM_NAME = "createPostComplaintForm";
const form = buildForm(FORM_NAME);

function PostComplaintFormContainer(props) {
  const { dispatch, submitSucceeded, closeHandler } = props;

  useEffect(() => {
    if (submitSucceeded) {
      closeHandler();
    }
  }, [submitSucceeded]);

  return <PostComplaintForm {...props} />;
}

function mapStateToProps(state, { communityId, postId }) {
  const user = getCurrentUser(state);

  return {
    initialValues: {
      userId: user?.id,
      postId,
    },
    communityId,
    postId,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createPostComplaint,
    enableReinitialize: true,
  })(PostComplaintFormContainer)
);
