import React from "react";
import { connect } from "react-redux";
import Classrooms from "./Classrooms";
import { getFetchedClassrooms } from "../../selectors/classroomSelectors";
import {
  isUserLegalAge,
  isUserCbtrgValid,
} from "../../selectors/userSelectors";

function ClassroomsContainer(props) {
  return <Classrooms {...props} />;
}

function mapStateToProps(state) {
  return {
    classrooms: getFetchedClassrooms(state),
    loading: state.classroomsList.loading,
    userLegalAge: isUserLegalAge(state),
    isUserCbtrgValid: isUserCbtrgValid(state),
  };
}

export default connect(mapStateToProps)(ClassroomsContainer);
