import { css } from "glamor";
import {
  buttonColors,
  spacing,
  styles,
  typographyColors,
  decorationColors,
  uiColors,
  colors,
} from "./theme";
import arrowLeft from "../library/images/arrow-left.svg";
import arrowRight from "../library/images/arrow-right.svg";

const linkResetStyle = {
  textDecoration: "none",
  color: buttonColors.primary,
};

export default function applyGlobalStyles() {
  css.global("body", {
    ...styles.defaultFontFamily,
    ...styles.bodyText,
  });
  css.global("h1, h2, h3, h4, h5, h6", {
    ...styles.defaultFontFamily,
    color: typographyColors.defaultTitle,
    fontWeight: "500",
    margin: 0,
    padding: 0,
  });
  css.global("h1, h2, h3, h4, h5, h6 + p", {
    marginBottom: spacing.xs,
  });
  css.global("p", {
    ...styles.defaultFontFamily,
    ...styles.bodyText,
    margin: 0,
    padding: 0,
  });
  css.global("p + p", {
    marginBottom: spacing.xs,
  });
  css.global("a", linkResetStyle);
  css.global("a:visited", linkResetStyle);
  css.global("a:focus", linkResetStyle);
  css.global("a:hover", linkResetStyle);
  css.global("a:active", linkResetStyle);

  // THEME OVERRIDES
  css.global(".ui.form input, button", {
    ...styles.defaultFontFamily,
  });
  css.global(".ui.form input, button", {
    minHeight: spacing.xl,
  });
  css.global(".ui.fluid.dropdown", {
    minHeight: spacing.xl,
    display: "flex",
    alignItems: "center",
  });
  css.global(".ui.fluid.dropdown>.dropdown.icon", {
    marginTop: "auto",
    marginBottom: "auto",
    paddingTop: "0.7em",
  });
  css.global(".ui.comments", {
    maxWidth: "100%",
  });
  css.global(".ui.comments .comment .comments", {
    ...styles.roundedCorners,
    marginTop: spacing.s,
    backgroundColor: uiColors.contentCards,
  });
  css.global(".ui.comments .comment .text", {
    ...styles.bodyText,
    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
  });
  css.global(".button", {
    fontWeight: "300 !important",
  });
  css.global(".ui.primary.button, .ui.primary.buttons .button", {
    backgroundColor: buttonColors.primary,
  });
  css.global(".ui.primary.button:hover, .ui.primary.buttons .button:hover", {
    backgroundColor: buttonColors.primaryDark,
  });
  css.global(".ui.violet.menu .active.item", {
    color: decorationColors.decorationPurple,
    borderColor: decorationColors.decorationPurple,
  });
  css.global(".ui.secondary.button", {
    backgroundColor: colors.white,
    color: "black !important",
    border: `2px solid ${colors.greyLight}`,
  });
  css.global(".ui.secondary.button:hover, .ui.secondary.button:focus", {
    backgroundColor: `${colors.greyLighter}`,
    color: "black !important",
    border: `2px solid ${colors.greyLight}`,
  });
  css.global(".ui.button.violet", {
    backgroundColor: decorationColors.decorationPurple,
    color: "white !important",
  });
  css.global(".ui.button.violet:hover, .ui.button.violet:focus", {
    backgroundColor: `${decorationColors.decorationPurple}DD`,
    color: "white",
  });
  css.global(".ui.button.green", {
    backgroundColor: decorationColors.decorationGreen,
    color: "white !important",
  });
  css.global(".ui.button.green:hover, .ui.button.green:focus", {
    backgroundColor: `${decorationColors.decorationGreen}DD`,
    color: "white",
  });
  css.global(".ui.button.orange", {
    backgroundColor: decorationColors.decorationOrange,
    color: "white !important",
  });
  css.global(".ui.button.orange:hover, .ui.button.orange:focus", {
    backgroundColor: `${decorationColors.decorationOrange}DD`,
    color: "white",
  });
  css.global(".ui.button.red", {
    backgroundColor: decorationColors.decorationRed,
    color: "white !important",
  });
  css.global(".ui.button.red:hover, .ui.button.red:focus", {
    backgroundColor: `${decorationColors.decorationRed}DD`,
    color: "white",
  });
  css.global(".ui.warning.label", {
    backgroundColor: decorationColors.decorationOrange,
    borderColor: decorationColors.decorationOrange,
    color: "white !important",
  });
  css.global(".ui.danger.label", {
    backgroundColor: decorationColors.decorationRed,
    borderColor: decorationColors.decorationRed,
    color: "white !important",
  });
  css.global(".ui.search>.results", {
    left: "auto",
    right: 0,
  });
  css.global(".ui.search>.results .result", {
    padding: 0,
  });
  css.global(".ui.mini.modal", {
    width: 380,
  });
  css.global(".carousel .control-prev.control-arrow:before", {
    height: "20px",
    width: "11px",
    borderRight: "0px !important",
    content: `url(${arrowLeft}) !important`,
  });
  css.global(".carousel .control-next.control-arrow:before", {
    height: "20px",
    width: "11px",
    borderLeft: "0px !important",
    content: `url(${arrowRight}) !important`,
  });
  css.global(
    ".carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before",
    {
      display: "inline !important",
    }
  );
  css.global(".ui.progress .bar", {
    backgroundColor: colors.brand,
    minWidth: 0,
  });
}
