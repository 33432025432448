import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const faqSchema = new schema.Entity("faqs");

export const fetchFaqs = (dispatch) => {
  apiGet("/api/v1/faqs").then((response) => {
    dispatch({
      type: "FAQS_FETCHED",
      ...normalize(response.body.data, new schema.Array(faqSchema)),
    });
  });
};
