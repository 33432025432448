import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import { deleteComment } from "../../processes/commentProcesses";
import Comment from "./Comment";
import { getCommentById } from "../../selectors/commentSelectors";
import { getBadgesByIds } from "../../selectors/configSelectors";

function CommentContainer(props) {
  const { dispatch, comment } = props;

  const [moderationModalOpen, setModerationModalOpen] = useState(false);

  const openModerationModalHandler = useCallback(() => {
    setModerationModalOpen(true);
  }, []);

  const closeModerationModalHandler = useCallback(() => {
    setModerationModalOpen(false);
  }, []);

  const deleteHandler = useCallback(() => {
    deleteComment(dispatch, comment);
  }, [dispatch, comment]);

  return (
    <Comment
      deleteHandler={deleteHandler}
      moderationModalOpen={moderationModalOpen}
      openModerationModalHandler={openModerationModalHandler}
      closeModerationModalHandler={closeModerationModalHandler}
      {...props}
    />
  );
}

function mapStateToProps(state, { comment }) {
  return {
    user: getCurrentUser(state),
    badges: getBadgesByIds(state, comment?.meta?.user?.badgeIds),
  };
}

export default connect(mapStateToProps)(CommentContainer);
