const getClassrooms = (action) => action.entities.classrooms;

export default function classroomsReducer(state = {}, action) {
  switch (action.type) {
    case "CLASSROOM_FETCHED":
    case "CLASSROOMS_FETCHED":
      return { ...state, ...getClassrooms(action) };
    default:
      return state;
  }
}
