import React from "react";
import {
  Form,
  Button,
  spacing,
  TextAreaField,
  typographyColors,
  uiColors,
  Link,
} from "../library";
import { css } from "glamor";
import { IoIosClose } from "react-icons/io";

const formClass = css({
  padding: `${spacing.m} ${spacing.l} ${spacing.l} ${spacing.l}`,
  maxWidth: "600px",
}).toString();
const headerClass = css({
  paddingBottom: spacing.s,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
const closeClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: spacing.xs,
  marginRight: `-${spacing.s}`,
  borderRadius: "50%",
  color: uiColors.border,
  cursor: "pointer",
  ":hover": {
    color: typographyColors.defaultBody,
    backgroundColor: "#00000020",
  },
});
const textAreaClass = css({
  padding: spacing.s,
});
const footerClass = css({
  marginTop: spacing.m,
  display: "flex",
  justifyContent: "right",
  gap: spacing.s,
  alignItems: "center",
});
const titleClass = css({
  fontWeight: "bolder",
  color: typographyColors.defaultBody,
});
const warningClass = css({
  marginBottom: spacing.s,
  color: `${typographyColors.defaultBody}AA`,
});

export default function PostComplaintForm(props) {
  const {
    handleSubmit,
    error,
    pristine,
    submitting,
    invalid,
    closeHandler,
    communityId,
  } = props;

  return (
    <Form onSubmit={handleSubmit} error={error} className={formClass}>
      <div className={headerClass}>
        <div className={titleClass}>Denunciar publicação</div>
        <div onClick={closeHandler} className={closeClass}>
          <IoIosClose size={22} />
        </div>
      </div>
      <div className={warningClass}>
        Agradecemos sua denúncia. Por favor, dê uma breve justificativa para
        melhor entendermos sua preocupação. Obrigado por ajudar a manter nossa
        comunidade segura
      </div>
      <TextAreaField
        name="text"
        autoFocus
        placeholder="Digite aqui..."
        className={textAreaClass}
        required
      />
      <div className={footerClass}>
        <Link component={Button} secondary to={`/communities/${communityId}`}>
          Voltar a publicação
        </Link>
        <Button
          type="submit"
          color="red"
          disabled={invalid || pristine || submitting}
        >
          Denunciar
        </Button>
      </div>
    </Form>
  );
}
