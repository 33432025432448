import React from "react";
import LessonListItemContainer from "../lessons/LessonListItemContainer";
import { Form, SearchField } from "../library/forms";

const formStyle = {
  display: "flex",
  justifyContent: "end",
};

const resultRenderer = ({ lesson, key }) => (
  <LessonListItemContainer key={key} lesson={lesson} />
);

export default function ClassroomSearchLessonForm(props) {
  const { loading, lessons } = props;

  return (
    <Form onSubmit={props.handleSubmit} error={props.error} style={formStyle}>
      <SearchField
        name="nameOrKeywordsCont"
        placeholder="Pesquisar..."
        resultRenderer={resultRenderer}
        loading={loading}
        results={lessons}
      />
    </Form>
  );
}
