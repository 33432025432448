import React from "react";
import { Rating as Stars } from "semantic-ui-react";
import { css } from "glamor";
import { spacing } from "../library/theme";

const container = css({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});
const titleStyle = css({
  marginTop: spacing.s,
});

export default function Rating(props) {
  const { onRate, title, className, value, defaultValue } = props;

  return (
    <div className={css(container, className)}>
      <h4 className={titleStyle}>{title}</h4>
      <Stars
        icon="star"
        maxRating={5}
        rating={value || defaultValue}
        size="large"
        onRate={onRate}
      />
    </div>
  );
}
