import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

export const attachmentSchema = new schema.Entity("attachments");

export const fetchAttachments = (dispatch, classroomId, lessonId) => {
  apiGet(
    `/api/v1/classrooms/${classroomId}/lessons/${lessonId}/attachments`
  ).then((response) => {
    dispatch({
      type: "ATTACHMENTS_FETCHED",
      ...normalize(response.body.data, new schema.Array(attachmentSchema)),
    });
  });
};
