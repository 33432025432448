import React from "react";
import { Card, Container, Grid } from "semantic-ui-react";
import Button from "../library/Button";
import Link from "../library/Link";
import { Form, TextField } from "../library/forms";
import { spacing } from "../library/theme";

const titleStyle = { padding: spacing.xs };
const buttonsWrapper = {
  display: "flex",
  justifyContent: "space-between",
};

export default function ChangePasswordForm(props) {
  return (
    <Container>
      <Grid padded centered>
        <Grid.Column computer={8} mobile={16}>
          <Form onSubmit={props.handleSubmit} error={props.error}>
            <Card fluid>
              <Card.Header>
                <h3 style={titleStyle}>Alterar minha senha</h3>
              </Card.Header>
              <Card.Content>
                <TextField
                  name="password"
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  label="Nova senha"
                  placeholder="*******"
                  fluid
                />
                <TextField
                  name="passwordConfirmation"
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  label="Repita a nova senha"
                  placeholder="*******"
                  fluid
                />
                <TextField
                  name="currentPassword"
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  label="Senha Atual"
                  hint="É necessário digitar a senha atual para confirmar. Ao atualizar a senha, você será deslogado e redirecionado para a tela de login."
                  placeholder="*******"
                  fluid
                />
              </Card.Content>
            </Card>
            <div style={buttonsWrapper}>
              <Button color="green" size="large">
                Alterar senha
              </Button>

              <Link
                to="/profile/edit"
                component={Button}
                type="button"
                size="large"
              >
                Editar Perfil
              </Link>
            </div>
          </Form>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
