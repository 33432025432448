import React from "react";
import LobbyLayout from "../lobby/LobbyLayout";
import RecoveryPasswordFormContainer from "./RecoveryPasswordFormContainer";

export default function RecoveryPasswordPage() {
  return (
    <LobbyLayout>
      <RecoveryPasswordFormContainer />
    </LobbyLayout>
  );
}
