import React from "react";
import { connect } from "react-redux";
import ClassroomContents from "./ClassroomContents";
import { getLevelGroupsByClassroom } from "../../selectors/levelGroupSelectors";

function ClassroomContentsContainer(props) {
  return <ClassroomContents {...props} />;
}
function mapStateToProps(state) {
  return {
    levelGroups: getLevelGroupsByClassroom(state),
  };
}

export default connect(mapStateToProps)(ClassroomContentsContainer);
