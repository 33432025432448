import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";

const faqContent = css({
  whiteSpace: "pre-line",
  wordBreak: "break-word",
});

export default function FaqAccordion(props) {
  const { faqs } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const clickHandler = useCallback(
    (e, titleProps) => {
      setActiveIndex(titleProps.index);
    },
    [activeIndex]
  );

  return faqs.length > 0 ? (
    <Accordion fluid styled>
      {faqs?.map((faq, index) => (
        <div key={faq.title}>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={clickHandler}
          >
            <Icon name="dropdown" />
            {faq.title}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <div
              className={faqContent}
              dangerouslySetInnerHTML={{ __html: faq.text }}
            />
          </Accordion.Content>
        </div>
      ))}
    </Accordion>
  ) : null;
}
