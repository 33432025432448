const getInvoices = (action) => action.entities.invoices;

export default function invoicesReducer(state = {}, action) {
  switch (action.type) {
    case "INVOICES_FETCHED":
      return { ...state, ...getInvoices(action) };
    default:
      return state;
  }
}
