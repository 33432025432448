const getLikes = (action) => action.entities.likes;

export default function likesReducer(state = {}, action) {
  switch (action.type) {
    case "POSTS_FETCHED":
    case "LIKE_CREATED":
      return { ...state, ...getLikes(action) };
    case "LIKE_DELETED":
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
