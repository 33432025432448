import React from "react";
import Button from "../library/Button";
import { Table } from "semantic-ui-react";
import Link from "../library/Link";

export default function ContractListItem(props) {
  const { classroomsContract } = props;
  return (
    <Table.Row verticalAlign="middle">
      <Table.Cell width="4">{classroomsContract?.title}</Table.Cell>
      <Table.Cell width="1" textAlign="right">
        <Link
          component={Button}
          href={`/classrooms/${classroomsContract?.classroomId}/contracts/${classroomsContract?.contractId}`}
          target="_blank"
        >
          Baixar
        </Link>
      </Table.Cell>
    </Table.Row>
  );
}
