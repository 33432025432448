import React from "react";
import { css } from "glamor";
import { stateColors } from "../library/theme";

const container = css({
  width: "100%",
  position: "relative",
  paddingBottom: "60.93%",
  height: 0,
});
const painel = css({
  overflow: "hidden",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: stateColors.empty,
});
const content = css({
  textAlign: "center",
});

export default function LessonImage({ image }) {
  return (
    <div className={container}>
      <div className={painel}>
        <div className={content}>
          <img src={image} width="100%" />
        </div>
      </div>
    </div>
  );
}
