import React from "react";
import { Container, Grid } from "semantic-ui-react";
import RecoveryPasswordFormContainer from "./RecoveryPasswordFormContainer";

export default function RecoveryPasswordLoggedPage() {
  return (
    <Container>
      <Grid padded centered>
        <Grid.Column computer={8} mobile={16} textAlign="center">
          <RecoveryPasswordFormContainer logged />
        </Grid.Column>
      </Grid>
    </Container>
  );
}
