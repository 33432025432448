import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import LikeModal from "./LikeModal";
import {
  getLikesByLikeable,
  getLikesByType,
} from "../../selectors/likeSelectors";

function LikeModalContainer(props) {
  const [open, setOpen] = useState(false);
  const toggleHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return <LikeModal open={open} toggleHandler={toggleHandler} {...props} />;
}

function mapStateToProps(state, { likeableType, likeableId }) {
  return {
    all: getLikesByLikeable(state, {
      likeableType,
      likeableId,
    }),
    defaults: getLikesByType(state, {
      likeableType,
      likeableId,
      type: "default",
    }),
    congrats: getLikesByType(state, {
      likeableType,
      likeableId,
      type: "congrats",
    }),
    loves: getLikesByType(state, {
      likeableType,
      likeableId,
      type: "love",
    }),
    awesomes: getLikesByType(state, {
      likeableType,
      likeableId,
      type: "awesome",
    }),
  };
}

export default connect(mapStateToProps)(LikeModalContainer);
