import React from "react";
import { css } from "glamor";
import {
  Button,
  Form,
  TextAreaField,
  TextField,
  decorationColors,
  spacing,
  uiColors,
} from "../library";

const MAX_LENGTH = 600;

const container = css({
  marginTop: spacing.s,
  overflow: "hidden",
});
const textFieldWrapper = css({
  borderRadius: spacing.xs,
  padding: spacing.s,
  border: `1px solid ${uiColors.border}`,
  "> div > span > #formMessage": {
    top: "-2px !important",
  },
  ":focus-within": {
    border: `1px solid ${decorationColors.decorationGreen}`,
  },
});
const textAreaClass = css({
  padding: "0px !important",
  border: "none !important",
  lineHeight: 2,
  ":hover, :focus": {
    border: "none !important",
  },
});
const buttonWrapper = {
  display: "flex",
  marginTop: spacing.s,
  justifyContent: "flex-end",
  gap: spacing.xs,
};
const hideClass = css({
  margin: 0,
  padding: 0,
  height: "0px !important",
  width: "0px !important",
});
const ctaClass = css({
  display: "flex",
  flexWrap: "wrap",
  gap: spacing.s,
  marginBottom: spacing.s,
  marginTop: spacing.m,
  borderTop: `1px solid ${uiColors.border}`,
  paddingTop: spacing.l,
});
const ctaFieldWrapper = css({
  flex: 1,
  minWidth: "280px",
  "> div > span > div > input": {
    ":focus": {
      border: `1px solid ${decorationColors.decorationGreen} !important`,
    },
  },
});

export default function CommentForm({
  handleSubmit,
  anyTouched,
  pristine,
  invalid,
  submitting,
  inputRef,
  dirty,
  currentUser,
  clearTextHandler,
}) {
  return (
    <Form
      onSubmit={handleSubmit}
      className={css(container, !anyTouched && hideClass)}
    >
      {currentUser?.isAdmin && (
        <div className={ctaClass}>
          <div className={ctaFieldWrapper}>
            <TextField name="ctaTitle" placeholder="Título do Link/CTA" fluid />
          </div>
          <div className={ctaFieldWrapper}>
            <TextField name="ctaUrl" placeholder="Url do Link/CTA" fluid />
          </div>
        </div>
      )}
      <div className={textFieldWrapper}>
        <TextAreaField
          name="text"
          placeholder="Adicionar um comentário"
          maxLength={!currentUser?.isAdmin && MAX_LENGTH}
          inputRef={inputRef}
          rows={pristine ? 1 : 3}
          required
          validationMessages={{ required: "*" }}
          fluid="true"
          className={textAreaClass}
        />
        {dirty && (
          <div style={buttonWrapper}>
            <Button size="mini" secondary onClick={clearTextHandler}>
              Cancelar
            </Button>
            <Button size="mini" color="green" disabled={submitting || invalid}>
              Comentar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
}
