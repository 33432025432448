import React from "react";
import { css } from "glamor";

const circularBorder = {
  borderRadius: "50%",
  width: 25,
  height: 25,
};
const circularBorderPosition = css({
  ...circularBorder,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function LikeIcon({ icon, color, zIndex = 1 }) {
  const Icon = icon;

  return (
    <span
      style={{
        ...circularBorder,
        backgroundColor: "white",
        zIndex: zIndex,
      }}
    >
      <div
        className={css(circularBorderPosition, {
          backgroundColor: `${color}30`,
          zIndex: zIndex,
        })}
      >
        <Icon
          size={16}
          style={{
            color: color,
          }}
        />
      </div>
    </span>
  );
}
