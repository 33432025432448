import { normalize, schema } from "normalizr";
import { apiGet, apiPost, apiDel } from "./helpers/api";
import { commentSchema } from "./commentProcesses";

export const likeSchema = new schema.Entity("likes");

export const createLike = (like, dispatch) => {
  const controller = `${like?.likeableType?.toLowerCase()}s`;

  return apiPost(`/api/v1/${controller}/${like?.likeableId}/likes`)
    .send(like)
    .then((response) => {
      dispatch({
        type: "LIKE_CREATED",
        ...normalize(response.body.data, likeSchema),
      });
    });
};

export const deleteLike = (dispatch, id) => {
  return apiDel(`/api/v1/likes/${id}`).then((response) => {
    dispatch({ type: "LIKE_DELETED", id });
  });
};
