import React from "react";
import { Table } from "semantic-ui-react";
import Page from "../library/Page";
import InvoiceListItemContainer from "./InvoiceListItemContainer";

export default function Invoices(props) {
  const { invoices } = props;
  return (
    <Page title="Notas Fiscais">
      <Table>
        <Table.Body>
          {invoices.map((invoice) => (
            <InvoiceListItemContainer
              invoiceId={invoice.id}
              key={`invoice${invoice.id}`}
            />
          ))}
        </Table.Body>
      </Table>
    </Page>
  );
}
