import React from "react";
import { Route, Switch } from "react-router-dom";
import LMS from "../components/LMS";
import NotFound from "../components/NotFound";
import Classroom from "../components/classrooms/ClassroomContainer";
import ClassroomsContainer from "../components/classrooms/ClassroomsContainer";
import CommunityContainer from "../components/community/CommunityContainer";
import ContractsListContainer from "../components/contracts/ContractsListContainer";
import FaqsContainer from "../components/faq/FaqsContainer";
import InvoicesContainer from "../components/invoices/InvoicesContainer";
import LessonContainer from "../components/lessons/LessonContainer";
import ProfileEditFormContainer from "../components/profileEdit/ProfileEditFormContainer";
import SupportPage from "../components/support/SupportPage";
import PostFormModalContainer from "../components/posts/PostFormModalContainer";
import PostComplaintFormModalContainer from "../components/posts/PostComplaintFormModalContainer";
import CertificateContainer from "../components/certificates/CertificateContainer";
import RecoveryPasswordLoggedPage from "../components/recoveryPassword/RecoveryPasswordLoggedPage";
import NewPasswordLoggedPage from "../components/recoveryPassword/NewPasswordLoggedPage";

const SignInRedirect = (props) => {
  props.history.replace("/");
  return null;
};

export default function LmsRoutes(props) {
  return (
    <LMS {...props}>
      <Switch>
        <Route path="/" component={ClassroomsContainer} exact />
        <Route path="/signin" component={SignInRedirect} exact />
        <Route
          path="/forgotten-password"
          component={RecoveryPasswordLoggedPage}
          exact
        />
        <Route path="/reset-password" component={NewPasswordLoggedPage} exact />
        <Route
          path="/communities/:communityId/"
          component={CommunityContainer}
          exact
        />
        <Route
          path="/communities/:communityId/posts/new"
          component={CommunityContainer}
          exact
        />
        <Route
          path="/communities/:communityId/posts/:postId/postComplaints/new"
          component={CommunityContainer}
          exact
        />
        <Route path="/classrooms/:id/" component={Classroom} exact />
        <Route
          path="/classrooms/:id/lessons/:id"
          component={LessonContainer}
          exact
        />
        <Route
          path="/profile/edit"
          component={ProfileEditFormContainer}
          exact
        />
        <Route
          path="/password/edit"
          component={ProfileEditFormContainer}
          exact
        />
        <Route path="/certificates" component={CertificateContainer} />
        <Route path="/faqs" component={FaqsContainer} exact />
        <Route path="/support" component={ClassroomsContainer} exact />
        <Route path="/invoices" component={InvoicesContainer} exact />
        <Route path="/contracts" component={ContractsListContainer} exact />
        <Route path="*" component={NotFound} />
      </Switch>
      <Switch>
        <Route
          path="/communities/:communityId/posts/new"
          component={PostFormModalContainer}
          exact
        />
        <Route
          path="/communities/:communityId/posts/:postId/postComplaints/new"
          component={PostComplaintFormModalContainer}
          exact
        />
        <Route path="/support" component={SupportPage} exact />
      </Switch>
    </LMS>
  );
}
