import { createSelector } from "reselect";
import { getCurrentUser } from "./userSelectors";

const getComments = (state) => state.entities.comments;
const getId = (_, id) => id;

const getAllComments = createSelector(getComments, (comments) => {
  return Object.values(comments);
});

export const getCommentById = createSelector(
  getComments,
  getId,
  (comments, id) => {
    return comments[id];
  }
);

export const getPostComments = createSelector(
  getAllComments,
  getId,
  getCurrentUser,
  (comments, postId, user) => {
    return comments.filter(
      (comment) =>
        comment.commentableType == "Post" &&
        comment.commentableId == postId &&
        (comment.status == "enabled" ||
          comment.userId == user?.id ||
          (user?.isAdmin &&
            (comment.status == "pending" || comment.status == "disabled")))
    );
  }
);
