import { createSelector } from "reselect";
import { getLessonId } from "./lessonSelectors";

export const getAttachments = (state) => state.entities.attachments;

export const getAttachmentsByLesson = createSelector(
  getLessonId,
  getAttachments,
  (lessonId, attachments) =>
    Object.values(attachments).filter(
      (attachment) =>
        attachment.attachmentableType === "Lesson" &&
        attachment.attachmentableId === lessonId
    )
);
