import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { updateComment } from "../../processes/commentProcesses";
import CommentModerationForm from "./CommentModerationForm";
import { getCurrentUserId } from "../../selectors/userSelectors";

function mapStateToProps(state, { comment }) {
  const currentUserId = getCurrentUserId(state);

  return {
    initialValues: {
      ...comment,
      justificationNote: "",
      lastModeratorId: currentUserId,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    onSubmit: updateComment,
  })(CommentModerationForm)
);
