import React, { useRef, useCallback } from "react";
import { css } from "glamor";
import {
  spacing,
  uiColors,
  typographyColors,
  decorationColors,
  Dropdown,
  DropdownItem,
  Link,
  Label,
  Badge,
  utilityColors,
} from "../library";
import { fromNow } from "../../utils/formatDate";
import { HiDotsVertical } from "react-icons/hi";
import { CgComment } from "react-icons/cg";
import CommentContainer from "../comments/CommentContainer";
import CommentFormContainer from "../comments/CommentFormContainer";
import { BsPin } from "react-icons/bs";
import LikeModalContainer from "../likes/LikeModalContainer";
import LikeButtonContainer from "../likes/LikeButtonContainer";
import { makeIsMobile } from "../../utils/useScreenWidth";
import getShortenName from "../../utils/getShortenName";
import { colors } from "../library/theme";

const container = css({
  borderRadius: spacing.m,
  backgroundColor: uiColors.fieldBackground,
  padding: spacing.m,
  maxWidth: "800px",
  marginLeft: "auto",
  marginRight: "auto",
  "& + &": {
    marginTop: spacing.m,
  },
  boxShadow: `0px 3px 4px ${utilityColors.shadow}`,
});
const headerClass = css({
  display: "flex",
  justifyContent: "space-between",
  gap: spacing.s,
  marginBottom: spacing.m,
  "> div > .ui.dropdown>.text": {
    display: "inline",
  },
});
const authorClass = css({
  color: typographyColors.defaultTitle,
});
const authorNameClass = css({ fontWeight: 600, margin: 0 });
const createdAtClass = css({ display: "block", lineHeight: 1 });
const avatarStyle = { height: "40px", width: "40px", borderRadius: "50%" };
const postInfoClass = css({
  flex: 1,
  display: "flex",
  gap: spacing.s,
});
const optionsWrapperClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  height: "40px !important",
  width: "40px",
  marginBottom: "auto",
  ":hover": {
    backgroundColor: "#00000020",
  },
});
const content = css({
  color: typographyColors.defaultTitle,
  paddingBottom: spacing.s,
});
const countersClass = css({
  display: "flex",
  padding: `0px 0px ${spacing.s} 0px`,
  borderBottom: `1px solid ${uiColors.border}`,
  gap: spacing.s,
});
const actionsClass = css({
  padding: `${spacing.s} 0px 0px 0px`,
  marginLeft: `-${spacing.xs}`,
  display: "flex",
});
const actionStyle = {
  color: decorationColors.decorationPurple,
  backgroundColor: "#F3F5FF",
};
const actionClass = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  cursor: "pointer",
  borderRadius: spacing.xs,
  paddingLeft: spacing.xs,
  paddingRight: spacing.xs,
  width: "fit-content",
  ":hover": actionStyle,
});
const disabledActionClass = css({
  pointerEvents: "none",
  opacity: 0.4,
});
const rightWrapper = css({
  display: "flex",
  gap: spacing.s,
  height: 40,
});
const pinWrapper = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
});
const nameWithBadges = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xs,
});
const UserBadges = ({ badges }) => {
  return badges.map((badge) => {
    return <Badge {...badge} />;
  });
};
const labelStyle = {
  display: "flex",
  marginBottom: spacing.s,
  width: "fit-content",
  marginLeft: "auto",
};
const labelClass = {
  color: `${uiColors.borderInverse} !important`,
  fontSize: "0.8rem !important",
  borderRadius: "500rem !important",
};
const pendingClass = css({
  ...labelClass,
  backgroundColor: `${decorationColors.decorationYellow} !important`,
});
const rejectedClass = css({
  ...labelClass,
  backgroundColor: `${decorationColors.decorationRedLight} !important`,
});
const imageWrapperClass = css({
  marginTop: spacing.s,
  display: "inline-block",
  maxHeight: 406,
  ">img": {
    maxHeight: "inherit",
    maxWidth: "100%",
  },
});
const ctaClass = css({
  display: "block",
});
const showMoreCommentsLinkClass = css({
  display: "block",
  cursor: "pointer",
  marginTop: spacing.xs,
});
const embedClass = css({
  marginTop: spacing.s,
});

export default function Post({
  post,
  user,
  badges,
  deleteHandler,
  comments,
  togglePinHandler,
  pinnedPostIds,
  orderPinHandler,
  toggleCommentsHandler,
  commentsPagination,
  commentsPaginationHandler,
}) {
  const isAuthor = post?.userId == user?.id;
  const isAdmin = user?.isAdmin;
  const isPinned = post?.pinnedPosition != null;
  const isLastPinned =
    pinnedPostIds.indexOf(post.id) == pinnedPostIds.length - 1;
  const pinOptionText = isPinned ? "Desafixar publicação" : "Fixar publicação";
  const pinOptionColor = isPinned ? colors.danger : colors.success;
  const commentsCountText = `${comments?.length} ${
    comments?.length != 1 ? "Comentários" : "Comentário"
  }`;
  const commentsOptionText = post?.commentsEnabled
    ? "Desativar comentários"
    : "Ativar comentários";
  const commentsOptionColor = post?.commentsEnabled
    ? colors.danger
    : colors.success;
  const isMobile = makeIsMobile();

  const inputRef = useRef();
  const commentFocusHandler = useCallback(() => {
    inputRef?.current?.focus();
    inputRef?.current?.blur();
    inputRef?.current?.focus();
  });
  const commentsNumberToShow = commentsPagination * 3;

  return post ? (
    <div id={`post-${post.id}`} className={container}>
      {post.status == "pending" && (
        <Label style={labelStyle} className={pendingClass}>
          Aguardando validação de um moderador (Visível apenas para você)
        </Label>
      )}
      {post.status == "rejected" && (
        <Label style={labelStyle} className={rejectedClass}>
          Publicação rejeitada (Visível apenas para você)
        </Label>
      )}
      <div className={headerClass}>
        <div className={postInfoClass}>
          <img src={post.meta?.user?.avatarUrl} style={avatarStyle} />
          <div className={authorClass}>
            <div className={nameWithBadges}>
              <div className={authorNameClass}>
                {getShortenName(post.meta?.user?.name)}
              </div>
              {!isMobile && <UserBadges badges={badges} />}
            </div>
            <span className={createdAtClass}>{fromNow(post.createdAt)}</span>
            {isMobile && <UserBadges badges={badges} />}
          </div>
        </div>
        <div className={rightWrapper}>
          {isPinned && (
            <div className={pinWrapper}>
              <BsPin /> {!isMobile && "Publicação Fixada"}
            </div>
          )}
          <Dropdown
            item
            icon={
              <div className={optionsWrapperClass}>
                <HiDotsVertical size={22} />
              </div>
            }
            direction="left"
          >
            <Dropdown.Menu>
              {(isAuthor || isAdmin) && (
                <DropdownItem
                  onClick={deleteHandler}
                  key="delete"
                  content="Excluir publicação"
                  confirmationHeader="Deseja excluir publicação?"
                  confirmationText="Tem certeza? Esta ação não poderá ser desfeita."
                  confirmButton="Excluir publicação"
                  confirmColor={colors.danger}
                />
              )}
              {!isAuthor && (
                <Link
                  to={`/communities/${post.communityId}/posts/${post.id}/postComplaints/new`}
                  component={DropdownItem}
                  key="complaint"
                  content="Denunciar publicação"
                />
              )}
              {isAdmin && (
                <>
                  {isPinned && !isLastPinned && (
                    <DropdownItem
                      onClick={orderPinHandler}
                      key="orderPin"
                      content="Mover para cima"
                    />
                  )}
                  <DropdownItem
                    onClick={togglePinHandler}
                    key="pin"
                    content={pinOptionText}
                    confirmationHeader={`Deseja ${pinOptionText.toLowerCase()}?`}
                    confirmationText={true}
                    confirmButton={pinOptionText}
                    confirmColor={pinOptionColor}
                  />
                  <DropdownItem
                    onClick={toggleCommentsHandler}
                    key="comments"
                    content={commentsOptionText}
                    confirmationHeader={`Deseja ${commentsOptionText.toLowerCase()}?`}
                    confirmationText={true}
                    confirmButton={commentsOptionText}
                    confirmColor={commentsOptionColor}
                  />
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className={content}>
        <div>{post.text}</div>

        {post.imageUrl && (
          <div className={imageWrapperClass}>
            <img src={post.imageUrl} height="100%" />
          </div>
        )}

        {post?.ctaUrl && (
          <Link href={post?.ctaUrl} target="_blank" className={ctaClass}>
            {post?.ctaTitle ? post?.ctaTitle : post?.ctaUrl}
          </Link>
        )}

        <div
          className={embedClass}
          dangerouslySetInnerHTML={{ __html: post?.videoUrl }}
        />
      </div>
      <div className={countersClass}>
        <LikeModalContainer
          likeableType="Post"
          likeableId={post.id}
          showIcons
        />
        <div>{commentsCountText}</div>
      </div>
      <div className={actionsClass}>
        <LikeButtonContainer likeableType="Post" likeableId={post.id} />

        <div
          className={css(
            actionClass,
            !post?.commentsEnabled && disabledActionClass
          )}
          onClick={commentFocusHandler}
        >
          <CgComment size={16} /> Comentar
        </div>
      </div>

      {comments?.slice(0, commentsNumberToShow).map((comment) => (
        <CommentContainer key={comment?.id} comment={comment} />
      ))}
      {comments?.length > commentsNumberToShow && (
        <Link
          onClick={commentsPaginationHandler}
          target="_blank"
          className={showMoreCommentsLinkClass}
        >
          Ver mais comentários...
        </Link>
      )}
      {post?.commentsEnabled && (
        <CommentFormContainer postId={post.id} inputRef={inputRef} />
      )}
    </div>
  ) : null;
}
