import { createSelector } from "reselect";
import { getCurrentClassroomId } from "./classroomSelectors";
import { getCourseIds } from "./courseSelectors";

const getBanners = (state) => state.entities.banners;
const getBannerType = (_, bannerType) => bannerType;

export const getBannersByType = createSelector(
  getBanners,
  getBannerType,
  getCurrentClassroomId,
  getCourseIds,
  (banners, bannerType, classroomId, courseIds) =>
    Object.values(banners).filter((banner) => {
      switch (bannerType) {
        case "lessons":
          return (
            banner.bannerType == "lessons" &&
            banner.classroomIds.includes(classroomId)
          );
        default:
          return (
            banner?.bannerType == bannerType &&
            (banner?.courseIds == [] ||
              !banner.courseIds?.some((courseId) =>
                courseIds?.includes(courseId)
              ))
          );
      }
    })
);
