export const spacing = {
  xxs: "4px",
  xs: "8px",
  s: "12px",
  m: "20px",
  l: "32px",
  xl: "52px",
  xxl: "84px",
};

export const colors = {
  brand: "#162F54",
  brandDark: "#162F54",
  white: "#fff",
  greyLightest: "#f9f9f9",
  greyLighter: "#f6f6f6",
  greyLight: "#cfd2d4",
  greyDark: "#606A71",
  greyDarker: "#363E43",
  greyDarkest: "#1D2224",
  brown: "#b78751",
  danger: "#FB202A",
  dangerLight: "#F36363",
  warning: "#FD8101",
  warningLight: "#F6C386",
  success: "#53B467",
  decorationSoft: "#fcc8b9",
  decorationHard: "#aadeca",
  decorationPurple: "#526AE3",
  shadow: "rgba(0, 0, 0, 0.1)",
  overlay: "rgba(2, 13, 66, 0.40)",
};

export const decorationColors = {
  decorationBrand: colors.brand,
  decorationBrandDark: colors.brandDark,
  decorationSoft: colors.decorationSoft,
  decorationHard: colors.decorationHard,
  decorationPurple: colors.decorationPurple,
  decorationBrown: colors.brown,
  decorationGreen: colors.success,
  decorationOrange: colors.warning,
  decorationRed: colors.danger,
  decorationRedLight: colors.dangerLight,
  decorationYellow: colors.warningLight,
};

export const utilityColors = {
  information: colors.brand,
  danger: colors.danger,
  success: colors.success,
  warning: colors.warning,
  shadow: colors.shadow,
  overlay: colors.overlay,
};

export const typographyColors = {
  defaultTitle: colors.greyDarker,
  defaultBody: colors.greyDark,
  inverseTitle: colors.white,
  inverseBody: colors.greyLightest,
};

export const uiColors = {
  background: colors.brand,
  backgroundDark: colors.brandDark,
  backgroundDarker: colors.greyDarker,
  backgroundDarkest: colors.greyDarkest,
  backgroundLightest: colors.greyLightest,
  border: colors.greyLight,
  borderInverse: colors.white,
  fieldBackground: colors.white,
  contentCards: colors.greyLighter,
};

export const stateColors = {
  focus: colors.brand,
  hover: colors.greyDark,
  empty: colors.greyLight,
  filled: colors.greyLighter,
  disabled: colors.greyLightest,
};

export const buttonColors = {
  primary: colors.brand,
  primaryDark: colors.brandDark,
  positive: colors.success,
  negative: colors.danger,
  warning: colors.warning,
  default: colors.greyLighter,
  textPrimary: colors.white,
  textDefault: colors.greyDark,
};

export const styles = {
  defaultFontFamily: {
    fontFamily: "Poppins !important",
    fontWeight: "400",
  },
  roundedCorners: { borderRadius: spacing.xxs },
  border: {
    boxShadow: `0 0 0 1px ${uiColors.border} inset`,
  },
  shadow: { boxShadow: `0 2px 5px ${utilityColors.shadow}` },
  transition: { transition: "all 150ms ease-in" },
  bodyText: {
    color: typographyColors.defaultBody,
    fontSize: 13,
    letterSpacing: "-0.3px",
    lineHeight: 2,
  },
  wrappingText: {
    lineHeight: 2,
  },
  bodyTextBold: {
    fontWeight: 600,
  },
  bodyTextItalic: {
    fontStyle: "italic",
  },
  bodyTextLarge: {
    fontSize: 15,
  },
  monospace: {
    fontFamily: "monospace",
    letterSpacing: "normal",
  },
  bodyTextSmall: {
    fontSize: 12,
  },
  whiteText: {
    color: typographyColors.inverseTitle,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
  whiteBodyText: {
    color: typographyColors.inverseBody,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
};
