import React from "react";
import { Button, Container } from "semantic-ui-react";
import Link from "./library/Link";
import { spacing } from "./library/theme";

const layout = { padding: spacing.m };
const buttonMargin = { marginTop: spacing.m };

export default function NotFound() {
  return (
    <Container textAlign="center" style={layout}>
      <h2>Página Não Encontrada</h2>
      <p>O conteúdo que você está tentando acessar não existe.</p>
      <Link to="/" component={Button} style={buttonMargin}>
        Ir para página principal
      </Link>
    </Container>
  );
}
