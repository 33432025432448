import React from "react";
import Rating from "../library/Rating";
import { css } from "glamor";
import { spacing, uiColors } from "../library/theme";

const container = css({
  backgroundColor: uiColors.fieldBackground,
  padding: spacing.m,
  marginTop: spacing.m,
});

export default function LessonRating({ lessonRatingHandler, canRate }) {
  return canRate ? (
    <div className={container}>
      <Rating
        title="O que você achou desta aula?"
        onRate={lessonRatingHandler}
      />
    </div>
  ) : null;
}
