import { css } from "glamor";
import React from "react";
import { createTextMask } from "redux-form-input-masks";
import { Card, Grid } from "semantic-ui-react";

import makeDropDownOptions from "../../utils/makeDropDownOptions";
import Button from "../library/Button";
import Container from "../library/Container";
import {
  CheckBoxField,
  DropDownField,
  FileField,
  Form,
  ImageField,
  TextAreaField,
  TextField,
} from "../library/forms";
import { spacing } from "../library/theme";
import Link from "../library/Link";
import { newDate } from "../../utils/formatDate";

const titleStyle = { padding: spacing.xs };
const userNameStyle = { marginTop: spacing.xs };
const zipCodeMask = createTextMask({
  pattern: "99999-999",
  guide: false,
  allowEmpty: true,
});
const whatsappMask = createTextMask({
  pattern: "99 99 99999-9999",
  guide: false,
  allowEmpty: true,
});
const cpfMask = createTextMask({
  pattern: "999.999.999-99",
  guide: false,
  allowEmpty: true,
});
const dateMask = createTextMask({
  pattern: "99/99/9999",
  guide: false,
  allowEmpty: true,
});
const locationClass = css({
  display: "flex",
  gap: spacing.m,
  alignItems: "center",
  margin: `${spacing.m} 0`,
});
const foreignerClass = css({
  gap: spacing.m,
  margin: `${spacing.m} 0`,
});
const stateClass = css({
  flex: 2,
});
const cityClass = css({ flex: 3 });
const neighborhood = { flex: 2 };
const streetNumber = { flex: 1 };
const buttonsWrapper = {
  display: "flex",
  justifyContent: "space-between",
};

const validateBirthdate = (value) => {
  if (
    !newDate(value, "DDMMYYYY").isBefore(
      newDate().startOf("year").subtract(3, "years")
    )
  )
    return "date";
  else return undefined;
};

const validateZipCode = (value) => {
  if (value?.length < 8) return "zipCode";
  else return undefined;
};

export default function ProfileEditForm(props) {
  const { setChangePasswordHandler, stateId, searchingAddress } = props;

  return (
    <Container>
      <Grid padded centered>
        <Grid.Column computer={2} mobile={16} textAlign="center">
          <ImageField name="avatarUrl" />
          <h3 style={userNameStyle}>{props.currentUser?.name}</h3>
        </Grid.Column>
        <Grid.Column computer={8} mobile={16}>
          <Form onSubmit={props.handleSubmit} error={props.error}>
            <Card fluid>
              <Card.Header>
                <h3 style={titleStyle}>Editar meu Perfil</h3>
              </Card.Header>
              <Card.Content>
                <TextField
                  name="name"
                  label="Seu nome"
                  placeholder="Nome de usuário..."
                  disabled
                  fluid
                />
                <TextField
                  name="email"
                  type="email"
                  label="E-mail"
                  placeholder="E-mail..."
                  disabled
                  required
                  fluid
                />
                <TextField
                  name="birthdate"
                  type="maskedDate"
                  label="Data de nascimento"
                  validate={validateBirthdate}
                  required
                  fluid
                  {...dateMask}
                />
                <DropDownField
                  name="gender"
                  label="Gênero"
                  options={makeDropDownOptions(props.genders)}
                  required
                  selection
                  fluid
                />
                <CheckBoxField
                  name="isForeigner"
                  label="Resido fora do Brasil"
                  checked={props.isForeigner}
                />
                {props?.isForeigner ? (
                  <>
                    <TextField
                      name="document"
                      label="Passport / Documento de Identificação"
                      fluid
                      required
                    />
                    <div className={foreignerClass}>
                      <TextAreaField
                        name="address"
                        label="Endereço"
                        rows={2}
                        placeholder="Digite seu endereço completo"
                        required
                        fluid
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <TextField
                      name="document"
                      label="CPF"
                      placeholder="999.999.999-99"
                      fluid
                      required
                      {...cpfMask}
                    />
                    <TextField
                      name="zipCode"
                      label="CEP"
                      placeholder="CEP"
                      loading={searchingAddress}
                      validate={validateZipCode}
                      required
                      fluid
                      {...zipCodeMask}
                    />
                    <TextField
                      name="address"
                      label="Endereço"
                      placeholder="Endereço"
                      loading={searchingAddress}
                      disabled={searchingAddress}
                      required
                      fluid
                    />
                    <div className={locationClass}>
                      <div style={neighborhood}>
                        <TextField
                          name="neighborhood"
                          label="Bairro"
                          placeholder="Bairro"
                          loading={searchingAddress}
                          disabled={searchingAddress}
                          required
                          fluid
                        />
                      </div>
                      <div style={streetNumber}>
                        <TextField
                          name="streetNumber"
                          label="Número"
                          placeholder="Número"
                          type="number"
                          required
                          fluid
                        />
                      </div>
                    </div>
                    <div className={locationClass}>
                      <div className={stateClass}>
                        <DropDownField
                          name="stateId"
                          label="Estado"
                          options={makeDropDownOptions(props.states)}
                          loading={searchingAddress}
                          disabled={searchingAddress}
                          required
                          selection
                          fluid
                        />
                      </div>
                      <div className={cityClass}>
                        <DropDownField
                          label="Cidade"
                          name="cityId"
                          options={makeDropDownOptions(props.cities)}
                          loading={searchingAddress}
                          disabled={searchingAddress || !stateId}
                          required
                          selection
                          fluid
                        />
                      </div>
                    </div>
                  </>
                )}
                <TextField
                  name="whatsapp"
                  label="Whatsapp"
                  placeholder="55 99 99999-9999"
                  required
                  fluid
                  {...whatsappMask}
                />
                <FileField
                  name="rgUrl"
                  label="Envie aqui o seu documento de identificação"
                  placeholder="Documento"
                />
                <FileField
                  name="addressProofUrl"
                  label="Envie aqui o seu comprovante de residência"
                  placeholder="Comprovante"
                />
              </Card.Content>
            </Card>
            <div style={buttonsWrapper}>
              <Button color="green" size="large">
                Atualizar meu perfil
              </Button>
              <Link
                to="/password/edit"
                component={Button}
                type="button"
                size="large"
                onClick={setChangePasswordHandler}
              >
                Alterar minha senha
              </Link>
            </div>
          </Form>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
