const getCommunities = (action) => action.entities.communities;

export default function communitiesReducer(state = {}, action) {
  switch (action.type) {
    case "COMMUNITY_FETCHED":
    case "COMMUNITIES_FETCHED":
      return { ...state, ...getCommunities(action) };
    default:
      return state;
  }
}
