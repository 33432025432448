import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { fetchCities } from "../../processes/configProccess";
import { updateProfile } from "../../processes/profileProcesses";
import { searchAddressForProfileEdit } from "../../processes/postalCodeProcesses";
import { getCitiesByStateId, getConfig } from "../../selectors/configSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import buildForm from "../library/forms/buildForm";
import ChangePasswordForm from "./ChangePasswordForm";
import ProfileEditForm from "./ProfileEditForm";
import formatDate from "../../utils/formatDate";

const form = buildForm("profileEdit");

function ProfileEditFormContainer(props) {
  const { stateId, dispatch, pathname } = props;

  useEffect(() => {
    stateId && fetchCities(dispatch, stateId);
  }, [dispatch, stateId]);

  if (pathname == "/password/edit") {
    return <ChangePasswordForm {...props} />;
  }

  return <ProfileEditForm {...props} />;
}

function mapStateToProps(state, ownProps) {
  const currentUser = getCurrentUser(state);
  const profileEditFormSelector = formValueSelector("profileEdit");
  const {
    stateId,
    isForeigner,
    zipCode,
    searchingAddress,
    searchedAddress,
    searchedNeighborhood,
    searchedStateId,
    searchedCityId,
  } = profileEditFormSelector(
    state,
    "stateId",
    "isForeigner",
    "updated",
    "zipCode",
    "searchingAddress",
    "searchedAddress",
    "searchedNeighborhood",
    "searchedStateId",
    "searchedCityId"
  );
  const gendersOptions = [
    { name: "Masculino", value: "male" },
    { name: "Feminino", value: "female" },
    { name: "Outros", value: "other" },
  ];

  return {
    initialValues: {
      ...currentUser,
      birthdate: formatDate(currentUser?.birthdate, "DDMMYYYY"),
      updated: true,
    },
    imageUrl: form.getValue("imageUrl", state),
    currentUser,
    states: getConfig(state, "states"),
    cities: getCitiesByStateId(state, stateId),
    genders: gendersOptions,
    stateId,
    isForeigner,
    pathname: ownProps.location.pathname,
    zipCode,
    searchingAddress,
    searchedAddress,
    searchedNeighborhood,
    searchedStateId,
    searchedCityId,
  };
}

const asyncValidate = (values, dispatch, props) => {
  const { zipCode, searchedZipCode } = values;
  const initialZipCode = props?.initialValues?.zipCode;
  const isZipCodeChanged =
    zipCode != initialZipCode ||
    (searchedZipCode && searchedZipCode != initialZipCode);
  const enoughZipLength = zipCode?.replace(/[^0-9]+/g, "")?.length == 8;
  const alreadySearched = zipCode == searchedZipCode;

  if (isZipCodeChanged && !alreadySearched && enoughZipLength) {
    searchAddressForProfileEdit(dispatch, {
      postalCode: zipCode,
      formName: "profileEdit",
    });
  }

  return Promise.resolve(true);
};

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: updateProfile,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    asyncValidate,
  })(ProfileEditFormContainer)
);
