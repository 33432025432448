import React from "react";
import { getAllClassroomsContracts } from "../../selectors/clasroomsContractSelectors";
import ContractsList from "./ContractsList";
import { useSelector } from "react-redux";
import { useLoadContracts } from "./useLoadContracts";

export default function ContractsListContainer(props) {
  const { loading } = useLoadContracts();

  const classroomsContracts = useSelector((state) =>
    getAllClassroomsContracts(state)
  );

  return (
    <ContractsList
      loading={loading}
      classroomsContracts={classroomsContracts}
    />
  );
}
