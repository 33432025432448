import { normalize, schema } from "normalizr";
import { apiPost, apiDel, apiPut } from "./helpers/api";
import { notifySubmitSucceeded, notifySubmitFail } from "./notifierProcesses";
import { likeSchema } from "./likeProcesses";

export const commentSchema = new schema.Entity("comments", {
  meta: { likes: [likeSchema] },
});

export const createComment = (comment, dispatch, { postId, reset }) => {
  return apiPost(`/api/v1/posts/${postId}/comments`)
    .send(comment)
    .then((response) => {
      dispatch({
        type: "COMMENT_CREATED",
        ...normalize({ ...response.body.data }, commentSchema),
      });
      reset();
      notifySubmitSucceeded(
        dispatch,
        "Seu comentário será exibido depois que for aprovado."
      );
    });
};

export const deleteComment = (dispatch, { id }) => {
  return apiDel(`/api/v1/comments/${id}`).then((response) => {
    dispatch({ type: "COMMENT_DELETED", id });
  });
};

export const updateComment = (comment, dispatch, props) => {
  if (!comment?.id) return;

  return apiPut(`/api/v1/comments/${comment.id}`)
    .send(comment)
    .then((response) => {
      dispatch({
        type: "COMMENT_UPDATED",
        ...normalize(response.body.data, commentSchema),
      });

      props?.closeHandler();
    })
    .catch((err) => {
      notifySubmitFail(dispatch, "Não conseguimos salvar sua ação...");
    });
};
