import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const surveyUser = new schema.Entity("surveysUsers");

export const fetchSurveysUsers = (dispatch) => {
  dispatch({ type: "SURVEYS_USERS_FETCH_REQUESTED" });
  apiGet(`/api/v1/surveys_users`).then((response) => {
    dispatch({
      type: "SURVEYS_USERS_FETCHED",
      ...normalize(response.body.data, new schema.Array(surveyUser)),
    });
  });
};
