import { css } from "glamor";
import React from "react";
import { Icon, Modal as SModal } from "semantic-ui-react";
import Button from "../library/Button";
import Link from "../library/Link";
import { styles } from "../library/theme";

const imageContainer = css({
  ...styles.roundedCorners,
  height: 0,
  paddingBottom: "56.25%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});

export default function Modal(props) {
  const { currentUser, modal, closeHandler, skipHandler } = props;

  const openCondition = !!currentUser && modal;

  return openCondition ? (
    <SModal open>
      {modal?.title && <SModal.Header>{modal?.title}</SModal.Header>}
      <SModal.Content>
        {modal?.meta?.isImagePresent && (
          <div
            className={css(imageContainer, {
              backgroundImage: `url(${modal?.meta?.image})`,
            })}
          ></div>
        )}
        {modal?.videoUrl && (
          <div dangerouslySetInnerHTML={{ __html: modal.videoUrl }} />
        )}
      </SModal.Content>
      <SModal.Actions>
        {modal?.url && (
          <Link primary component={Button} href={modal?.url} target="_blank">
            <Icon name="checkmark" />
            Acessar
          </Link>
        )}
        <Button onClick={closeHandler}>
          <Icon name="close" />
          Fechar
        </Button>
        <Button onClick={skipHandler}>
          <Icon name="close" />
          Fechar e não exibir novamente
        </Button>
      </SModal.Actions>
    </SModal>
  ) : null;
}
