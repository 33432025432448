import { normalize, schema } from "normalizr";
import { apiPost } from "./helpers/api";

export const view = new schema.Entity("views");

export const createView = (dispatch, params) => {
  apiPost(`/api/v1/views/`)
    .send(params)
    .then((response) => {
      dispatch({
        type: "VIEW_CREATED",
        ...normalize(response.body.data, view),
      });
    });
};
