import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchClassroomsContracts } from "../../processes/classroomsContractProcesses";

export function useLoadContracts() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await fetchClassroomsContracts(dispatch);
      setLoading(false);
    }
    fetchData();
  }, [dispatch]);

  return { loading };
}
