import React from "react";
import { css } from "glamor";
import { Accordion, Icon } from "semantic-ui-react";
import LevelListItemContainer from "../levels/LevelListItemContainer";
import { styles, spacing, uiColors, typographyColors } from "../library/theme";
import Spinner from "../library/Spinner";

const layout = css({
  overflow: "hidden",
  "& + &": { marginTop: 1 },
});
const content = css({
  margin: 0,
  padding: `${spacing.m} !important`,
  border: `1px solid ${uiColors.border}`,
  borderTop: 0,
});
const titleWrapper = css(styles.roundedCorners, {
  border: `1px solid ${uiColors.border} !important`,
  background: uiColors.fieldBackground,
  padding: `${spacing.s} !important`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  lineHeight: 1,
});
const title = css({
  margin: 0,
  paddingRight: spacing.s,
  maxWidth: "100%",
});
const compactTitle = css({
  borderRadius: 0,
  border: 0,
  backgroundColor: uiColors.fieldBackground,
});
const compactContent = css({ border: 0 });
const iconStyle = { color: typographyColors.defaultTitle };
const titleWithDuration = css({ lineHeight: 1 });

export default function LevelListItem({
  levelGroup,
  levels,
  handleClick,
  active,
  compact,
  compliant,
  loading,
}) {
  const titleWrapperClass = css(titleWrapper, compact && compactTitle);
  const contentClass = css(content, compact && compactContent);
  return (
    <Accordion className={layout.toString()}>
      <Accordion.Title
        className={titleWrapperClass.toString()}
        onClick={handleClick}
      >
        <div>
          <p className={titleWithDuration}>
            <h5 className={title}>{levelGroup.name}</h5>
          </p>
        </div>
        <Icon name={active ? "chevron up" : "chevron down"} style={iconStyle} />
      </Accordion.Title>
      <Accordion.Content className={contentClass.toString()} active={active}>
        {loading ? (
          <Spinner text="Carregando módulos..." wrapped />
        ) : (
          <>
            {levels?.map((level, index) => (
              <LevelListItemContainer
                key={index}
                level={level}
                compact={compact}
                compliant={compliant}
              />
            ))}
          </>
        )}
      </Accordion.Content>
    </Accordion>
  );
}
