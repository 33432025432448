import React from "react";
import { css } from "glamor";
import { spacing, uiColors } from "./theme";
import Link from "./Link";
import { FaChevronLeft } from "react-icons/fa";
import { makeIsMobile } from "../../utils/useScreenWidth";
import PageAction from "./PageAction";
import { PiBookOpenText } from "react-icons/pi";

const titleClass = css({ marginBottom: 0, marginLeft: spacing.s });
const row = css({ display: "flex", flexDirection: "row" });
const header = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`,
  minHeight: "100px",
  gap: spacing.m,
});
const mobileHeader = css({
  minHeight: "auto",
});
const contentWrapper = css({
  padding: spacing.m,
});
const externalLinkClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 30,
  height: 30,
  ":hover": {
    borderRadius: spacing.m,
    backgroundColor: "#DFDFDF",
  },
});
const linkStyle = {
  marginLeft: spacing.m,
  marginRight: spacing.s,
  cursor: "pointer",
};
const externalLinkStyle = {
  display: "flex",
  cursor: "pointer",
  marginLeft: "auto",
};
const content = css({
  maxWidth: "1280px",
  margin: "0 auto",
});

export default function Page(props) {
  const {
    title,
    backlink,
    backlinkClickHandler,
    externalLink,
    externalLinkTitle = "",
    action,
    children,
    ...rest
  } = props;
  const backlinkEnabled = backlink || backlinkClickHandler;
  const isMobile = makeIsMobile();

  return (
    <div {...rest}>
      {title && (
        <div className={css(header, isMobile && mobileHeader)}>
          <div className={row}>
            {backlinkEnabled && (
              <Link
                to={backlink}
                onClick={backlinkClickHandler}
                style={linkStyle}
              >
                <FaChevronLeft size={12} /> VOLTAR
              </Link>
            )}
            <h3 className={titleClass}>{title}</h3>
          </div>
          {externalLink && (
            <Link href={externalLink} style={externalLinkStyle} target="_blank">
              <div className={externalLinkClass}>
                <PiBookOpenText size={22} />{" "}
              </div>
              {externalLinkTitle && (
                <p style={{ gap: spacing.xs }}>{externalLinkTitle}</p>
              )}
            </Link>
          )}
          <PageAction isMobile={isMobile} content={action} />
        </div>
      )}
      <div className={contentWrapper}>
        <PageAction mobile isMobile={isMobile} content={action} />
        <div className={content}>{children}</div>
      </div>
    </div>
  );
}
