import { createSelector } from "reselect";

const getFaqs = (state) => state.entities.faqs;
const getCourseId = (_, courseId) => courseId;

export const getAllFaqs = createSelector(getFaqs, (faqs) =>
  Object.values(faqs)
);

export const getFaqByCourse = createSelector(
  getFaqs,
  getCourseId,
  (faqs, courseId) =>
    Object.values(faqs).filter((faq) => faq?.courseIds?.includes(courseId))
);

export const getFaqCourseIds = createSelector(getAllFaqs, (faqs) => {
  return faqs.reduce(
    (acc, curr) => [
      ...acc,
      ...curr.courseIds.filter((id) => !acc.courseIds?.includes(id)),
    ],
    []
  );
});
