import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import CommentForm from "./CommentForm";
import { createComment } from "../../processes/commentProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import { useCallback } from "react";

function CommentFormContainer(props) {
  const { reset, postId } = props;

  const clearTextHandler = useCallback(() => {
    reset(`createCommentForPost${postId}`);
  }, [reset, postId]);

  return <CommentForm clearTextHandler={clearTextHandler} {...props} />;
}

function mapStateToProps(state, { postId }) {
  return {
    form: `createCommentForPost${postId}`,
    initialValues: {},
    currentUser: getCurrentUser(state),
    postId,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    onSubmit: createComment,
  })(CommentFormContainer)
);
