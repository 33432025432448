import React, { useEffect } from "react";
import { connect } from "react-redux";
import Classroom from "./Classroom";
import { currentClassroom } from "../../selectors/classroomSelectors";
import { getNextNonWatchedLessonId } from "../../selectors/lessonSelectors";
import { createView } from "../../processes/viewProcesses";
import {
  getCurrentUser,
  isUserLegalAge,
  isUserCbtrgValid,
} from "../../selectors/userSelectors";
import { fetchClassroom } from "../../processes/classroomProcesses";
import { apoloProcess } from "../../processes/apoloProcesses";

function ClassroomContainer(props) {
  const { dispatch, classroom, currentUser } = props;

  useEffect(() => {
    if (classroom) {
      apoloProcess({ email: currentUser?.email }).then(() => {
        fetchClassroom(dispatch, classroom?.id);
      });
      createView(dispatch, {
        classroom_id: classroom?.id,
        target: "classroom",
        view_type: "view",
      });
    }
  }, [dispatch, currentUser?.email]);

  return <Classroom {...props} />;
}

function mapStateToProps(state) {
  return {
    classroom: currentClassroom(state),
    nextLessonId: getNextNonWatchedLessonId(state),
    userLegalAge: isUserLegalAge(state),
    currentUser: getCurrentUser(state),
    isUserCbtrgValid: isUserCbtrgValid(state),
  };
}

export default connect(mapStateToProps)(ClassroomContainer);
