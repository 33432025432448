import { createSelector } from "reselect";

const getCommunities = (state) => state.entities.communities;
const getId = (_, id) => id;

export const getAllCommunities = createSelector(getCommunities, (communities) =>
  Object.values(communities)
);

export const getCommunityById = createSelector(
  getCommunities,
  getId,
  (communities, id) => {
    return communities[id];
  }
);
