import React from "react";
import { Modal } from "../library";
import PostFormContainer from "./PostFormContainer";

export default function PostFormModal({ communityId, closeHandler }) {
  return (
    <Modal
      open
      onClose={closeHandler}
      size="tiny"
      closeOnDimmerClick={false}
      content={
        <PostFormContainer
          communityId={communityId}
          closeHandler={closeHandler}
        />
      }
    />
  );
}
