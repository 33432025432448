import { css, select } from "glamor";
import React from "react";
import Link from "./Link";
import { buttonColors, spacing, uiColors, typographyColors } from "./theme";

const iconClass = css({
  marginRight: spacing.s,
});
const activeClass = css(
  {
    transition: ".3s all",
    color: `${buttonColors.primary} !important`,
  },
  select(`& .${iconClass}`, {
    transition: ".3s opacity",
    opacity: 1,
  })
);
const item = css({
  display: "flex",
  alignItems: "center",
  padding: `${spacing.s} ${spacing.m}`,
  fontSize: 16,
  color: `${typographyColors.defaultBody} !important`,
  transition: ".3s all",
  height: 58,
  "&:hover": {
    ...activeClass,
  },
});
const disabledClass = css({
  color: uiColors.border,
  pointerEvents: "none",
  userSelect: "none",
  "&:hover": {
    borderBottom: "none",
  },
  "&:visited": {
    borderBottom: "none",
    color: uiColors.border,
  },
});
const disabledIconStyle = {
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
};

function MenuItemLabel({ label, active }) {
  return active ? <strong>{label}</strong> : <span>{label}</span>;
}

export default function MenuItem(props) {
  const {
    label,
    icon,
    active,
    disabled,
    children,
    className,
    disabledIcon,
    ...rest
  } = props;
  const baseClass = css(
    item,
    className && className,
    active && activeClass,
    disabled && disabledClass
  );
  const Icon = icon;

  return (
    <Link className={baseClass} {...rest}>
      {icon && <Icon size={22} className={iconClass} />}
      {label ? <MenuItemLabel label={label} active={active} /> : children}
      {disabled && disabledIcon && (
        <span style={disabledIconStyle}>{disabledIcon}</span>
      )}
    </Link>
  );
}
