import React from "react";
import { css } from "glamor";
import { spacing, uiColors, decorationColors, Float } from "../library";
import { BiLike, BiHeart, BiMedal } from "react-icons/bi";
import { MdOutlineSignLanguage } from "react-icons/md";
import LikeOptionContainer from "./LikeOptionContainer";

const actionClass = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  cursor: "pointer",
  borderRadius: spacing.xs,
  paddingLeft: spacing.xs,
  paddingRight: spacing.xs,
  width: "fit-content",
});
const likesWrapper = css({
  display: "flex",
  backgroundColor: uiColors.fieldBackground,
  borderRadius: spacing.m,
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  padding: spacing.xxs,
  marginBottom: spacing.s,
});

const options = {
  undefined: { icon: <BiLike size={16} />, label: "Gostei" },
  default: {
    icon: <BiLike size={16} />,
    label: "Gostei",
    color: decorationColors.decorationGreen,
  },
  congrats: {
    icon: <MdOutlineSignLanguage size={16} />,
    label: "Parabéns",
    color: decorationColors.decorationOrange,
  },
  love: {
    icon: <BiHeart size={16} />,
    label: "Amei",
    color: decorationColors.decorationRed,
  },
  awesome: {
    icon: <BiMedal size={16} />,
    label: "Genial",
    color: decorationColors.decorationPurple,
  },
};
export default function LikeButton({
  like,
  likeableType,
  likeableId,
  deleteLikeHandler,
}) {
  const likeIcon = options[like?.type]?.icon;
  const likeLabel = options[like?.type]?.label;
  const activeClass = css({
    color: options[like?.type]?.color,
  });
  const actionStyle = {
    color: options[like?.type]?.color,
  };

  return (
    <Float
      style={{ zIndex: 10 }}
      content={
        <div className={likesWrapper}>
          {Object.keys(options)
            .slice(1)
            .map((type) => (
              <LikeOptionContainer
                key={type}
                likeableType={likeableType}
                likeableId={likeableId}
                type={type}
              />
            ))}
        </div>
      }
      trigger={
        <div
          className={css(
            actionClass,
            { hover: actionStyle },
            like && activeClass
          )}
          onClick={deleteLikeHandler}
        >
          {likeIcon} {likeLabel}
        </div>
      }
    />
  );
}
