import React, { useState, useCallback, useLayoutEffect } from "react";
import { css } from "glamor";
import { spacing, uiColors, utilityColors } from "../library/theme";
import Spinner from "../library/Spinner";
import AnnotationsContainer from "../annotations/AnnotationsContainer";
import AttachmentListContainer from "../attachments/AttachmentListContainer";
import { Grid, Sidebar, Icon } from "semantic-ui-react";
import Button from "../library/Button";
import Link from "../library/Link";
import { FaChevronLeft, FaRegCheckCircle } from "react-icons/fa";
import ClassroomContentsContainer from "../classroomContents/ClassroomContentsContainer";
import LessonRating from "./LessonRating";
import LessonContent from "./LessonContent";
import LessonActions from "./LessonActions";

const desktopWrapper = css({ "@media(max-width: 991px)": { display: "none" } });
const rightSidebarStyle = {
  padding: 0,
  boxShadow: `0 2px 5px ${utilityColors.shadow}`,
  backgroundColor: uiColors.fieldBackground,
  width: "270px",
};
const leftSidebarStyle = {
  padding: 0,
  backgroundColor: uiColors.fieldBackground,
  boxShadow: `0 2px 5px ${utilityColors.shadow}`,
  width: "270px",
};
const contentWrapper = { display: "flex" };
const contentStyle = { flex: 1, padding: spacing.m, marginBottom: spacing.l };
const titleWrapper = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: spacing.m,
  height: 42,
});
const titleClass = css({ margin: 0 });
const titleAndButton = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: spacing.m,
});
const titleSidebar = { padding: 0, margin: 0 };
const leftColumnStyle = {};
const rightColumnStyle = {
  paddingBottom: 0,
};
const descriptionStyle = {
  paddingTop: spacing.m,
  wordWrap: "break-word",
  whiteSpace: "pre-line",
};
const attachmentsAndWatchedWrapper = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: spacing.m,
  textAlign: "right",
};
const watchedButtonClass = css({
  height: "40px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const butonsWrappper = css(titleAndButton, {
  padding: 0,
  marginTop: spacing.m,
});

export default function Lesson(props) {
  const {
    lesson,
    markAsWatchedHandler,
    canRate,
    lessonRatingHandler,
    currentClassroomId,
    prevLesson,
    nextLesson,
    blockContent,
    daysToUnlock,
    watchedPrevious,
    enrollmentCreatedAtDays,
    compliant,
    hasEnrollment,
    isCbtrg,
    isUserCbtrgValid,
    isUnlocked,
  } = props;
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const toggleLeftSidebar = useCallback(
    () => setShowLeftSidebar(!showLeftSidebar),
    [showLeftSidebar]
  );
  const hideLeftSidebar = useCallback(() => setShowLeftSidebar(false));
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const hideRightSidebar = useCallback(() => setShowRightSidebar(false));
  const toggleRightSidebar = useCallback(
    () => setShowRightSidebar(!showRightSidebar),
    [showRightSidebar]
  );

  const [screenWidth, setScreenWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    screenWidth > 992 &&
      (setShowRightSidebar(false), setShowLeftSidebar(false));
    return () => window.removeEventListener("resize", updateSize);
  }, [screenWidth]);

  const leftSidebarContent = () => (
    <>
      <div className={titleAndButton}>
        <Link to={`/classrooms/${currentClassroomId}`}>
          <FaChevronLeft size={12} /> VOLTAR PARA A NAVEGAÇÃO
        </Link>
        {showLeftSidebar && (
          <Button
            onClick={toggleLeftSidebar}
            icon="close"
            size="small"
          ></Button>
        )}
      </div>
      <ClassroomContentsContainer
        hideLeftSidebarHandler={hideLeftSidebar}
        compact
        compliant={compliant}
      />
    </>
  );
  const rightSidebarContent = () => (
    <>
      <div className={titleAndButton}>
        <h3 style={titleSidebar}>Anotações</h3>
        {showRightSidebar && (
          <Button
            onClick={toggleRightSidebar}
            icon="close"
            size="small"
          ></Button>
        )}
      </div>
      <AnnotationsContainer lessonId={lesson.id} />
    </>
  );

  return lesson ? (
    <Sidebar.Pushable>
      <Sidebar
        animation="push"
        icon="labeled"
        visible={showLeftSidebar}
        style={leftSidebarStyle}
        width="wide"
        onHide={hideLeftSidebar}
      >
        {leftSidebarContent()}
      </Sidebar>
      <Sidebar
        animation="push"
        icon="labeled"
        visible={showRightSidebar}
        style={rightSidebarStyle}
        direction="right"
        width="wide"
        onHide={hideRightSidebar}
      >
        {rightSidebarContent()}
      </Sidebar>
      <Sidebar.Pusher>
        <Grid columns={2} padded>
          <Grid.Column only="mobile tablet" style={leftColumnStyle}>
            <Button onClick={toggleLeftSidebar} icon="list ol" size="tiny" />
          </Grid.Column>
          <Grid.Column
            only="mobile tablet"
            style={rightColumnStyle}
            textAlign="right"
          >
            <Button
              onClick={toggleRightSidebar}
              icon="edit outline"
              size="tiny"
            />
          </Grid.Column>
        </Grid>
        <div style={contentWrapper}>
          <div className={css(desktopWrapper, leftSidebarStyle)}>
            {leftSidebarContent()}
          </div>
          <div style={contentStyle}>
            <div className={titleWrapper}>
              <h2 className={titleClass}>{lesson.name}</h2>
            </div>
            <LessonContent
              daysToUnlock={daysToUnlock}
              watchedPrevious={watchedPrevious}
              enrollmentCreatedAtDays={enrollmentCreatedAtDays}
              blockContent={blockContent}
              lesson={lesson}
              compliant={compliant}
              hasEnrollment={hasEnrollment}
              isCbtrg={isCbtrg}
              isUserCbtrgValid={isUserCbtrgValid}
              isUnlocked={isUnlocked}
            />
            <LessonActions actions={lesson?.actionButtons} />
            <LessonRating
              lessonRatingHandler={lessonRatingHandler}
              canRate={canRate}
            />
            <div style={attachmentsAndWatchedWrapper}>
              {!blockContent && compliant && isUnlocked && (
                <Button
                  onClick={markAsWatchedHandler}
                  className={watchedButtonClass.toString()}
                  disabled={lesson?.watched}
                >
                  <FaRegCheckCircle size={12} />
                  {lesson?.watched ? " Assistida" : " Marcar como Assistida"}
                </Button>
              )}
              <AttachmentListContainer title="Arquivos" lessonId={lesson.id} />
            </div>
            <div className={butonsWrappper}>
              <Link
                to={`/classrooms/${currentClassroomId}/lessons/${prevLesson?.id}`}
                component={Button}
                disabled={!prevLesson || !compliant || !isUnlocked}
              >
                <Icon name="angle left" />
                Aula Anterior
              </Link>
              <Link
                to={`/classrooms/${currentClassroomId}/lessons/${nextLesson?.id}`}
                component={Button}
                disabled={!nextLesson || !compliant || !isUnlocked}
              >
                Próxima Aula
                <Icon name="angle right" />
              </Link>
            </div>
            {lesson.description && (
              <div
                style={descriptionStyle}
                dangerouslySetInnerHTML={{
                  __html: lesson.descriptionHtml,
                }}
              ></div>
            )}
          </div>
          <div className={css(desktopWrapper, rightSidebarStyle)}>
            {rightSidebarContent()}
          </div>
        </div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  ) : (
    <Spinner text="Carregando informações da aula..." />
  );
}
