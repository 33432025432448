import React from "react";
import { Dropdown } from "semantic-ui-react";
import Page from "../library/Page";
import FaqAccordionContainer from "./FaqAccordionContainer";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { spacing } from "../library/theme";

const dropdownStyle = {
  marginBottom: spacing.m,
};

export default function Faqs(props) {
  const { courses, selectCourseHandler, selectedCourse } = props;

  return (
    <Page title="Perguntas Frequentes">
      <Dropdown
        fluid
        selection
        style={dropdownStyle}
        placeholder={selectedCourse?.name}
        onChange={selectCourseHandler}
        options={makeDropDownOptions(courses)}
      />
      <FaqAccordionContainer course={selectedCourse} />
    </Page>
  );
}
