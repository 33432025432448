import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { attachmentSchema } from "./attachmentProcesses";

const invoiceSchema = new schema.Entity("invoices", {
  meta: { attachments: [attachmentSchema] },
});

export const fetchInvoices = (dispatch) => {
  apiGet("/api/v1/invoices").then((response) => {
    dispatch({
      type: "INVOICES_FETCHED",
      ...normalize(response.body.data, new schema.Array(invoiceSchema)),
    });
    dispatch({
      type: "ATTACHMENTS_FETCHED",
      ...normalize(response.body.data, new schema.Array(invoiceSchema)),
    });
  });
};
