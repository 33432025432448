import { createSelector } from "reselect";
import { getAttachments } from "./attachmentSelectors";

const getInvoices = (state) => state.entities.invoices;
const getInvoiceId = (_, id) => id;

export const getAllInvoices = createSelector(getInvoices, (invoices) =>
  Object.values(invoices)
);

export const getInvoiceById = createSelector(
  getAllInvoices,
  getInvoiceId,
  (invoices, id) => invoices.find((invoice) => invoice.id === id)
);

export const getInvoiceFirstAttachment = createSelector(
  getAttachments,
  getInvoiceById,
  (attachments, invoice) =>
    invoice?.meta?.attachments?.length > 0 &&
    Object.values(attachments).find(
      (attachment) => attachment.id === invoice.meta.attachments[0]
    )
);
