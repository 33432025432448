import { apiGet, apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { fetchClassroom } from "./classroomProcesses";
import toQueryString from "../utils/toQueryString";

const lesson = new schema.Entity("lessons");

export const fetchLessons = (dispatch, classroomId) => {
  dispatch({ type: "LESSONS_FETCH_REQUESTED" });
  apiGet(`/api/v1/classrooms/${classroomId}/lessons`).then((response) => {
    dispatch({
      type: "LESSONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(lesson)),
    });
  });
};

export const markAsWatched = (dispatch, classroomId, lessonId) => {
  return apiPost(
    `/api/v1/classrooms/${classroomId}/lessons/${lessonId}/lessons_users`
  )
    .send({ lessonId })
    .then(() => {
      dispatch({ type: "LESSON_WATCHED", lessonId });
      fetchClassroom(dispatch, classroomId);
    })
    .catch(
      (err) =>
        new Error(`Error on create lesson user: ${err.response.body.errors}`)
    );
};

export const submitSearchFilter = (values, dispatch, { classroomId }) => {
  dispatch({ type: "CLASSROOM_LESSON_SEARCH_FETCH_REQUEST" });
  const searchParams = toQueryString(values);
  apiGet(`/api/v1/classrooms/${classroomId}/lessons?${searchParams}`).then(
    (response) => {
      dispatch({
        type: "LESSONS_FETCHED",
        ...normalize(response.body.data, new schema.Array(lesson)),
      });
      dispatch({
        type: "CLASSROOM_LESSON_SEARCH_FETCHED",
        ...normalize(response.body.data, new schema.Array(lesson)),
      });
    }
  );
};
