import React from "react";
import Label from "./Label";
import { css } from "glamor";

const userLabelStyle = {
  maxHeight: 20,
  margin: "auto 0",
  color: "#FFF !important",
  borderRadius: "29px !important",
};

export default function Badge({ name, color }) {
  return (
    <Label
      size="mini"
      className={css(userLabelStyle, {
        backgroundColor: `${color} !important`,
        borderColor: `${color} !important`,
      })}
    >
      {name}
    </Label>
  );
}
