import { connect } from "react-redux";
import { getAllInvoices } from "../../selectors/invoiceSelectors";
import Invoices from "./Invoices";

function mapStateToProps(state) {
  return {
    invoices: getAllInvoices(state),
  };
}

export default connect(mapStateToProps)(Invoices);
