import { replace } from "connected-react-router";
import { fetchClassrooms } from "./classroomProcesses";
import { apiPost } from "./helpers/api";

export const createEnrollment = (dispatch, classroomId, userId) => {
  return apiPost("/api/v1/enrollments")
    .send({ classroomId, userId })
    .then(() => {
      fetchClassrooms(dispatch);
      dispatch(replace("/"));
    });
};
