const initialState = {
  states: {},
  cities: {},
  badges: {},
  betaFeatures: {},
};

const getConfigs = (action) => action.entities;

export default function configsReducer(state = initialState, action) {
  switch (action.type) {
    case "CITIES_FETCHED":
      return {
        ...state,
        ...getConfigs(action),
      };
    case "CONFIGS_FETCHED":
      return {
        ...state,
        ...getConfigs(action),
        betaFeatures: action.betaFeatures,
      };
    default:
      return state;
  }
}
