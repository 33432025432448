import { connect } from "react-redux";
import ClassroomSearchLessonForm from "./ClassroomSearchLessonForm";
import { submitSearchFilter } from "../../processes/lessonProcesses";
import { reduxForm } from "redux-form";
import debounce from "../../utils/debounce";
import { getLessonsByIds } from "../../selectors/lessonSelectors";

const lessonOptions = (lessons) =>
  lessons?.slice(0, 15).map((lesson) => ({
    key: `search${lesson?.id}`,
    lesson: lesson,
  }));

function mapStateToProps(state) {
  return {
    initialValues: {},
    lessons: lessonOptions(
      getLessonsByIds(state, state.classroomsList.searchLessonIds)
    ),
    loading: state.classroomsList.searchLoading,
    classroomId: state.currentClassroomId,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "classroomSearchLesson",
    onChange: debounce(submitSearchFilter, 750),
  })(ClassroomSearchLessonForm)
);
