import React, { useCallback } from "react";
import { connect } from "react-redux";
import LikeOption from "./LikeOption";
import { getLike } from "../../selectors/likeSelectors";
import { createLike } from "../../processes/likeProcesses";

function LikeOptionContainer(props) {
  const { dispatch, type, likeableType, likeableId } = props;

  const createLikeHandler = useCallback(() => {
    createLike({ likeableType, likeableId, type }, dispatch);
  }, [dispatch, likeableType, likeableId, type]);

  return <LikeOption createLikeHandler={createLikeHandler} {...props} />;
}

function mapStateToProps(state, { likeableType, likeableId }) {
  return {
    like: getLike(state, { likeableType, likeableId }),
    likeableType,
    likeableId,
  };
}

export default connect(mapStateToProps)(LikeOptionContainer);
