import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCoursesWithFaq } from "../../selectors/courseSelectors";
import Faqs from "./Faqs";
import { fetchFaqs } from "../../processes/faqProcesses";

export const FaqsContainer = (props) => {
  const { dispatch, courses } = props;
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const selectedCourse = courses.find(
    (course) => course.id == selectedCourseId
  );

  const selectCourseHandler = useCallback((e, { value }) => {
    setSelectedCourseId(value);
  }, []);

  useEffect(() => {
    setSelectedCourseId(courses[0]?.id);
  }, [courses]);

  useEffect(() => {
    fetchFaqs(dispatch);
  }, [dispatch]);

  return courses.length > 0 ? (
    <Faqs
      selectedCourse={selectedCourse}
      selectCourseHandler={selectCourseHandler}
      {...props}
    />
  ) : null;
};

const mapStateToProps = (state) => ({
  courses: getCoursesWithFaq(state),
});

export default connect(mapStateToProps)(FaqsContainer);
