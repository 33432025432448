import { createSelector } from "reselect";
import { getLevelGroupsByClassroom } from "./levelGroupSelectors";
import { getLevels } from "./levelSelectors";
import { getCurrentUser } from "./userSelectors";

const getClassrooms = (state) => state.entities.classrooms;
export const getCurrentClassroomId = (state) => state.currentClassroomId;
export const getClassroomId = (_, id) => id;
const getClassroomFetchedIds = (state) => state.classroomsList.classroomIds;

export const getCurrentClassroom = createSelector(
  getClassrooms,
  getCurrentClassroomId,
  (classrooms, currentClassroomId) => classrooms[currentClassroomId]
);

export const getClassroom = createSelector(
  getClassrooms,
  getClassroomId,
  (classrooms, id) => classrooms[id]
);

export const getAllClassrooms = createSelector(getClassrooms, (classrooms) =>
  Object.values(classrooms)
);

export const getCourseIds = createSelector(getAllClassrooms, (classrooms) =>
  classrooms?.map((classroom) => classroom.courseId)
);

export const getAllClassroomsIds = createSelector(getClassrooms, (classrooms) =>
  Object.values(classrooms).map((classroom) => classroom.id)
);

export const getFetchedClassrooms = createSelector(
  getClassrooms,
  getClassroomFetchedIds,
  (classrooms, ids) =>
    Object.values(classrooms).filter(
      (classroom) => ids.includes(classroom.id) && classroom?.meta?.isUnlocked
    )
);

export const currentClassroom = createSelector(
  getClassrooms,
  getCurrentClassroomId,
  (classrooms, id) => classrooms[id]
);

export const getLevelsByClassroom = createSelector(
  getLevelGroupsByClassroom,
  getLevels,
  (levelGroups, levels) => {
    const levelGroupIds = levelGroups.map((item) => item.id);
    return Object.values(levels).filter((level) =>
      levelGroupIds.includes(level.levelGroupId)
    );
  }
);

export const getClassroomsForCertificatesUser = createSelector(
  getCurrentUser,
  (user) => {
    const classrooms = user?.meta?.classroomsForCertificates || [];

    return classrooms.filter((item) => !!item);
  }
);
