const getClassroomsContracts = (action) => action.entities.classroomsContracts;

export default function classroomsContractsReducer(state = {}, action) {
  switch (action.type) {
    case "CLASSROOMS_CONTRACTS_FETCHED":
      return { ...state, ...getClassroomsContracts(action) };
    default:
      return state;
  }
}
