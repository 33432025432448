import { css } from "glamor";
import React from "react";
import { Popup, Table } from "semantic-ui-react";
import { Page } from "../library";
import { colors, spacing } from "../library/theme";
import CertificateProgress from "./CertificateProgress";

const contentContainer = css({
  borderRadius: spacing.m,
  padding: spacing.m,
  background: colors.white,
  "> table": {
    border: "none !important",
    boxShadow: "none !important",
  },
});
const emptyText = {
  padding: spacing.xxl,
  textAlign: "center",
};
const linkClass = css({
  color: colors.primary,
  display: "flex",
  justifyContent: "end",
  paddingRight: spacing.m,
});
const disabledLink = {
  color: colors.greyLight,
  cursor: "normal",
};

const tableHeaderCell = css({
  backgroundColor: `${colors.greyLight} !important`,
  ":first-child": {
    borderTopLeftRadius: `${spacing.xs} !important`,
    borderBottomLeftRadius: `${spacing.xs} !important`,
  },
  ":last-child": {
    borderTopRightRadius: `${spacing.xs} !important`,
    borderBottomRightRadius: `${spacing.xs} !important`,
  },
}).toString();

export default function Certificate(props) {
  const { classrooms, userLegalAge } = props;

  return (
    <Page title="Meus Certificados">
      {classrooms?.length ? (
        <div className={contentContainer}>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={tableHeaderCell}>
                  Curso
                </Table.HeaderCell>
                <Table.HeaderCell width={8} className={tableHeaderCell}>
                  Turma
                </Table.HeaderCell>
                <Table.HeaderCell className={tableHeaderCell}>
                  Progresso
                </Table.HeaderCell>
                <Table.HeaderCell
                  className={tableHeaderCell}
                ></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {classrooms.map((classroom, index) => {
                const { progress, certificateGenerationDate } = classroom?.meta;

                return (
                  <Table.Row key={index}>
                    <Table.Cell>{classroom.courseName}</Table.Cell>
                    <Table.Cell>{classroom.name}</Table.Cell>
                    <Table.Cell>
                      <CertificateProgress
                        separator="/"
                        {...classroom?.meta}
                        showCounters={false}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        disabled={!!certificateGenerationDate || progress >= 1}
                        content="O download ficará disponível quando você concluir 100% do curso"
                        on="hover"
                        position="top right"
                        trigger={
                          <div>
                            {(!!certificateGenerationDate || progress >= 1) &&
                            classroom?.hasCertificate &&
                            userLegalAge ? (
                              <a
                                href={`/classrooms/${classroom?.id}/certificates`}
                                target="_blank"
                                className={linkClass}
                              >
                                Download
                              </a>
                            ) : (
                              <p className={css(linkClass, disabledLink)}>
                                Download
                              </p>
                            )}
                          </div>
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <p style={emptyText}>Você ainda não tem certificados</p>
      )}
    </Page>
  );
}
