import { normalize, schema } from "normalizr";
import { apiPost } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";

const postComplaintSchema = new schema.Entity("postComplaints");

export const createPostComplaint = (postComplaint, dispatch) => {
  return apiPost(`/api/v1/posts/${postComplaint?.postId}/post_complaints`)
    .send(postComplaint)
    .then((response) => {
      dispatch({
        type: "POST_COMPLAINT_CREATED",
        ...normalize(response.body.data, postComplaintSchema),
      });
      notifySubmitSucceeded(dispatch, "Rebemos sua denúncia.");
    });
};
