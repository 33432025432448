import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchSurveysUsers } from "../../processes/surveysUsersProcesses";
import { getCurrentLesson } from "../../selectors/lessonSelectors";
import { makeAnsweredSurvey } from "../../selectors/surveyUserSelectors";
import LessonSurvey from "./LessonSurvey";

export const LessonSurveyContainer = (props) => {
  const { dispatch } = props;

  useEffect(() => {
    fetchSurveysUsers(dispatch);
  }, [dispatch]);

  return <LessonSurvey {...props} />;
};

function mapStateToProps(state) {
  const lesson = getCurrentLesson(state, state.currentLessonId);

  return {
    lesson,
    survey: lesson?.survey,
    loading: state.surveyUtils.loading,
    currentUserId: state.currentUserId,
    answered: makeAnsweredSurvey(state, lesson?.survey?.id),
  };
}

export default connect(mapStateToProps)(LessonSurveyContainer);
