import React from "react";
import { css } from "glamor";
import {
  spacing,
  uiColors,
  typographyColors,
  Dropdown,
  DropdownItem,
  Label,
  Link,
  decorationColors,
  Badge,
} from "../library";
import { fromNow } from "../../utils/formatDate";
import { HiDotsVertical } from "react-icons/hi";
import LikeButtonContainer from "../likes/LikeButtonContainer";
import LikeModalContainer from "../likes/LikeModalContainer";
import getShortenName from "../../utils/getShortenName";
import CommentModerationModal from "./CommentModerationModal";
import { colors } from "../library/theme";
import { makeIsMobile } from "../../utils/useScreenWidth";

const container = css({
  padding: spacing.s,
  backgroundColor: `${uiColors.borderInverse}`,
  borderRadius: spacing.xs,
  marginTop: spacing.s,
  border: `1px solid ${uiColors.border}`,
  wordBreak: "break-word",
});
const disabledColor = css({
  backgroundColor: `${uiColors.border}50`,
  border: "hidden",
});
const headerClass = css({
  display: "flex",
  justifyContent: "space-between",
  gap: spacing.s,
  marginBottom: spacing.s,
  "> .ui.dropdown>.text": {
    display: "inline",
  },
});
const authorClass = css({
  color: typographyColors.defaultTitle,
});
const authorNameClass = css({ fontWeight: 600 });
const createdAtClass = css({ display: "block", lineHeight: 1 });
const avatarStyle = { height: "40px", width: "40px", borderRadius: "50%" };
const commentInfoClass = css({
  flex: 1,
  display: "flex",
  gap: spacing.s,
});
const optionsWrapperClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  height: "40px !important",
  width: "40px",
  marginBottom: "auto",
  ":hover": {
    backgroundColor: "#00000020",
  },
});
const likeWrapper = css({
  display: "flex",
  margin: `${spacing.s} 0 0 -8px`,
  justifyContent: "space-between",
});
const labelStyle = {
  display: "flex",
  marginTop: spacing.xxs,
  marginBottom: spacing.xs,
  width: "fit-content",
  marginLeft: "auto",
};
const labelClass = {
  color: `${uiColors.borderInverse} !important`,
  fontSize: "0.8rem !important",
  borderRadius: "500rem !important",
};
const pendingClass = css({
  ...labelClass,
  backgroundColor: `${decorationColors.decorationYellow} !important`,
});
const rejectedClass = css({
  ...labelClass,
  backgroundColor: `${decorationColors.decorationRedLight} !important`,
});
const ctaClass = css({
  marginTop: spacing.m,
});
const nameWithBadges = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xs,
});
const UserBadges = ({ badges }) => {
  return badges.map((badge) => {
    return <Badge {...badge} />;
  });
};

export default function Comment({
  comment,
  user,
  badges,
  deleteHandler,
  moderationModalOpen,
  openModerationModalHandler,
  closeModerationModalHandler,
}) {
  const isAuthor = comment.userId == user?.id;
  const isAdmin = user?.isAdmin;
  const isEnabled = comment?.status == "enabled";
  const isMobile = makeIsMobile();

  return (
    <div className={css(container, !isEnabled && disabledColor)}>
      {comment.status == "pending" && (
        <Label style={labelStyle} className={pendingClass}>
          Aguardando validação de um moderador (Visível apenas para você)
        </Label>
      )}
      {comment.status == "disabled" && (
        <Label style={labelStyle} className={rejectedClass}>
          Comentário rejeitado (Visível apenas para você)
        </Label>
      )}
      {moderationModalOpen && (
        <CommentModerationModal
          comment={comment}
          closeHandler={closeModerationModalHandler}
        />
      )}
      <div className={headerClass}>
        <div className={commentInfoClass}>
          <img src={comment?.meta?.user?.avatarUrl} style={avatarStyle} />
          <div className={authorClass}>
            <div className={nameWithBadges}>
              <span className={authorNameClass}>
                {getShortenName(comment?.meta?.user?.name)}
              </span>
              {!isMobile && <UserBadges badges={badges} />}
            </div>
            <span className={createdAtClass}>{fromNow(comment.createdAt)}</span>
            {isMobile && <UserBadges badges={badges} />}
          </div>
        </div>
        <Dropdown
          item
          disabled={!isAuthor && !isAdmin}
          icon={
            <div className={optionsWrapperClass}>
              <HiDotsVertical size={22} />
            </div>
          }
          direction="left"
        >
          <Dropdown.Menu>
            {isAuthor && (
              <DropdownItem
                onClick={deleteHandler}
                key="delete"
                content="Excluir comentário"
                confirmationHeader="Deseja excluir comentário?"
                confirmationText="Tem certeza? Esta ação não poderá ser desfeita."
                confirmButton="Excluir comentário"
                confirmColor={colors.danger}
              >
                Excluir comentário
              </DropdownItem>
            )}
            {isAdmin && (
              <DropdownItem
                onClick={openModerationModalHandler}
                key="moderate"
                content="Moderar comentário"
              >
                Moderar comentário
              </DropdownItem>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div>{comment.text}</div>
      {comment?.ctaUrl && (
        <Link href={comment?.ctaUrl} target="_blank" className={ctaClass}>
          {comment?.ctaTitle ? comment?.ctaTitle : comment?.ctaUrl}
        </Link>
      )}
      <div className={likeWrapper}>
        <LikeButtonContainer likeableType="Comment" likeableId={comment?.id} />
        <LikeModalContainer likeableType="Comment" likeableId={comment?.id} />
      </div>
    </div>
  );
}
