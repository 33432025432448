import React, { useState, useCallback } from "react";
import {
  uiColors,
  typographyColors,
  spacing,
  Button,
  Link,
  Modal,
  Checkbox,
} from "../library";
import { css } from "glamor";
import { IoIosClose } from "react-icons/io";
import { BiInfoCircle } from "react-icons/bi";

const modalImageStyle = {
  width: "100%",
};
const compliantClass = css({
  padding: spacing.l,
  ">h4": {
    color: typographyColors.defaultBody,
    margin: 0,
  },
});
const complaintHeader = css({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: spacing.s,
  "> h3": {
    marginBottom: 0,
  },
});
const confirmationHeaderWrapper = css({
  display: "flex",
  gap: spacing.s,
  alignItems: "center",
  justifyContent: "space-between",
  color: typographyColors.defaultBody,
  padding: spacing.s,
  fontSize: 16,
  fontWeight: "bolder",
});
const closeClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: spacing.xs,
  borderRadius: "50%",
  color: uiColors.border,
  cursor: "pointer",
  margin: `-${spacing.m} -${spacing.m} 0 0`,
  ":hover": {
    color: typographyColors.defaultBody,
    backgroundColor: "#00000020",
  },
});
const footerClass = css({
  marginTop: spacing.m,
  borderTop: `1px solid ${uiColors.border}`,
  paddingTop: spacing.m,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  gap: spacing.m,
});
const linksWrapper = css({
  width: "100%",
  "> a ": {
    display: "flex",
    justifyContent: "center",
    textDecoration: "underline",
    cursor: "pointer",
  },
});
const infoWrapper = css({
  display: "flex",
  gap: spacing.xs,
  alignItems: "center",
});

const InfoText = ({ text }) => {
  return (
    <div className={infoWrapper}>
      <BiInfoCircle size={22} />
      {text}
    </div>
  );
};

const CommunityAccessModal = ({ community, requestAccessHandler }) => {
  const {
    accessStatus: status,
    compliant,
    isBanned,
    justificationNote,
    accessModalTitle: title,
    accessModalTermsUrl: termsUrl,
    accessModalImage: image,
    accessModalRejectedImage: rejectedImage,
  } = community;

  const [confirmed, setConfirmed] = useState(false);
  const confirmationHandler = useCallback((e, data) => {
    setConfirmed(data.checked);
  }, []);

  const [openTerms, setOpenTerms] = useState(false);
  const openTermsHandler = useCallback(() => {
    setOpenTerms("term");
  }, []);
  const exitTermsHandler = useCallback(() => {
    setOpenTerms(false);
  }, []);

  return compliant && status == "approved" && !isBanned ? null : (
    <Modal
      open
      content={
        <div className={compliantClass}>
          <div className={complaintHeader}>
            <h3>{title}</h3>
            <div className={confirmationHeaderWrapper}>
              <Link to="/" className={closeClass}>
                <IoIosClose size={22} />
              </Link>
            </div>
          </div>
          <img
            src={status == "rejected" ? rejectedImage : image}
            style={modalImageStyle}
          />
          {isBanned ? (
            <h4>Seu acesso foi suspenso temporáriamente.</h4>
          ) : (
            <h4>
              {compliant ? (
                <>
                  {status == null &&
                    "Para fazer parte da comunidade, pedimos que leia e concorde com as regras abaixo:"}
                  {status == "pending" &&
                    "Sua solicitação de acesso ainda está sob análise, tente novamente mais tarde."}
                  {status == "rejected" &&
                    "Sua solicitação foi rejeitada, pedimos que entre em contato com o suporte no site www.ibft.com.br."}
                </>
              ) : (
                "Sua solicitação foi rejeitada, pedimos que entre em contato com o suporte no site www.ibft.com.br."
              )}
            </h4>
          )}

          <div className={footerClass}>
            {isBanned && <h5>Justificação: {justificationNote}</h5>}
            {compliant && status == null && (
              <div className={linksWrapper}>
                <Link href={termsUrl} target="_blank">
                  Regras da comunidade
                </Link>
              </div>
            )}
            {compliant ? (
              <>
                {status == null && (
                  <Checkbox
                    label="Eu li e concordo com as regras da comunidade."
                    onChange={confirmationHandler}
                    checked={confirmed}
                  />
                )}
                {status == "rejected" && (
                  <InfoText text="Solicitação rejeitada" />
                )}
                {status == "pending" && (
                  <InfoText text="Solicitação em análise" />
                )}
              </>
            ) : (
              <InfoText text="Solicitação rejeitada" />
            )}
            {compliant ? (
              <>
                {status == null && (
                  <Button
                    color="green"
                    onClick={requestAccessHandler}
                    disabled={!confirmed}
                  >
                    Solicitar acesso
                  </Button>
                )}
                {(status == "rejected" || status == "pending") && (
                  <div>
                    <Link component={Button} color="orange" to="/">
                      Ir para Meus Cursos
                    </Link>
                    <Link
                      component={Button}
                      color="green"
                      href="https://www.ibft.com.br"
                      target="_blank"
                    >
                      Entrar em contato
                    </Link>
                  </div>
                )}
              </>
            ) : (
              <Link
                component={Button}
                color="green"
                href="https://www.ibft.com.br"
                target="_blank"
              >
                Entrar em contato
              </Link>
            )}
          </div>
        </div>
      }
    />
  );
};

export default CommunityAccessModal;
