import React from "react";
import Spinner from "../library/Spinner";
import Page from "../library/Page";
import Button from "../library/Button";
import Link from "../library/Link";
import ClassroomContentsContainer from "../classroomContents/ClassroomContentsContainer";
import { Grid, Icon, Progress } from "semantic-ui-react";
import { css } from "glamor";
import { colors, spacing, styles } from "../library/theme";
import BannersContainer from "../banner/BannersContainer";
import ClassroomSearchLessonFormContainer from "./ClassroomSearchLessonFormContainer";

const imageContainer = css({
  ...styles.roundedCorners,
  height: 0,
  paddingBottom: "56.25%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  position: "relative",
});
const lockedContainer = css({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 1,
  zIndex: 1,
  background: `${colors.brand}CF`,
  color: colors.white,
  fontWeight: "bold",
  textTransform: "uppercase",
});
const progressClass = css({ marginBottom: `${spacing.xxs} !important` });
const progressWrapper = css({
  paddingTop: spacing.m,
  textAlign: "center",
});
const bannerClass = css({
  margin: `${spacing.m} 0 0 0`,
});
const certificateButton = { margin: `${spacing.s} 0` };
const nextLessonStyle = {
  width: "100%",
  margin: `${spacing.s} 0`,
  textTransform: "uppercase",
};

export default function Lessons(props) {
  const { classroom, nextLessonId, userLegalAge, isUserCbtrgValid } = props;
  const certificateGenerationDate = classroom?.meta?.certificateGenerationDate;
  const compliant = classroom?.meta?.compliant;
  const progressValue = classroom?.meta?.progress * 100;
  const hasEnrollment = classroom?.meta?.hasEnrollment;
  const isUnlocked = classroom?.meta?.isUnlocked;
  const isCbtrg = classroom?.meta?.isCbtrg;

  const invalidEnrollment = hasEnrollment && !compliant;
  const invalidMembership = isCbtrg && !isUserCbtrgValid;
  const isLocked = invalidEnrollment || invalidMembership || !isUnlocked;

  const externalLink = invalidMembership
    ? "https://citrg.com/"
    : "https://www.ibft.com.br";

  const linkUrl = isLocked
    ? { href: externalLink, target: "_blank" }
    : { to: `/classrooms/${classroom?.id}/lessons/${nextLessonId}` };

  return classroom ? (
    <Page
      title={classroom.name}
      backlink="/"
      action={<ClassroomSearchLessonFormContainer />}
    >
      <Grid>
        <Grid.Column computer={4} mobile={16}>
          <div
            className={css(imageContainer, {
              backgroundImage: `url(${classroom?.imageUrl})`,
            })}
          >
            {isLocked && (
              <div className={lockedContainer}>
                <Icon name="lock" size="big" />
              </div>
            )}
          </div>
          <div className={progressWrapper}>
            <Progress
              percent={progressValue}
              className={progressClass.toString()}
              color="green"
              size="tiny"
            ></Progress>
            <small>{`${progressValue.toFixed(0)}%`}</small>
          </div>
          <Link
            {...linkUrl}
            component={Button}
            primary
            fluid
            style={nextLessonStyle}
            disabled={!nextLessonId}
          >
            {isLocked ? (
              <>
                <Icon name="unlock" /> Desbloquear
              </>
            ) : (
              <>
                <Icon name="play" /> Continuar assistindo
              </>
            )}
          </Link>
          {classroom?.description && <p>{classroom?.description}</p>}
          {classroom?.hasCertificate &&
            (!!certificateGenerationDate || progressValue >= 100) &&
            userLegalAge && (
              <Link
                component={Button}
                color="blue"
                href={`/classrooms/${classroom?.id}/certificates`}
                target="_blank"
                size="mini"
                fluid
                style={certificateButton}
              >
                Download do Certificado
              </Link>
            )}
          <BannersContainer bannerType="lessons" className={bannerClass} />
        </Grid.Column>
        <Grid.Column computer={12} mobile={16}>
          <ClassroomContentsContainer compliant={compliant} />
        </Grid.Column>
      </Grid>
    </Page>
  ) : (
    <Spinner text="Carregando informações do curso..." />
  );
}
