import React, { useEffect } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import SignUpForm from "./SignUpForm";
import { signUp } from "../../processes/signUpProcesses";
import { fetchClassrooms } from "../../processes/classroomProcesses";
import { getFetchedClassrooms } from "../../selectors/classroomSelectors";

function SignUpFormContainer(props) {
  const { dispatch } = props;

  useEffect(() => {
    fetchClassrooms(dispatch);
  }, [dispatch]);

  return <SignUpForm {...props} />;
}

function mapStateToProps(state) {
  return {
    initialValues: {},
    classrooms: getFetchedClassrooms(state),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "signUpForm",
    onSubmit: signUp,
  })(SignUpFormContainer)
);
