const getPosts = (action) => action.entities.posts;

export default function postsReducer(state = {}, action) {
  switch (action.type) {
    case "POST_CREATED":
    case "POST_UPDATED":
    case "POSTS_FETCHED":
      return { ...state, ...getPosts(action) };
    case "POST_PINNED_POSITION_CHANGED":
      return {
        ...state,
        [action.postId]: {
          ...state[action.postId],
          pinnedPosition: action.pinnedPosition,
        },
      };
    case "POST_DELETED":
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
