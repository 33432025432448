import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";

const lastVisitedClassroomId = localStorage.getItem("lastVisitedClassroomId");
let initialState = lastVisitedClassroomId
  ? Number(lastVisitedClassroomId)
  : null;

export default function currentClassroomReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const match = matchPath(action.payload.location.pathname, {
        path: "/classrooms/:classroomId",
      });
      const classroomId =
        match && match.params.classroomId && Number(match.params.classroomId);

      if (classroomId)
        localStorage.setItem("lastVisitedClassroomId", classroomId);

      return classroomId || state;
    default:
      return state;
  }
}
