import { connect } from "react-redux";
import { getClassroomsForCertificatesUser } from "../../selectors/classroomSelectors";
import { isUserLegalAge } from "../../selectors/userSelectors";
import Certificate from "./Certificate";

function mapStateToProps(state) {
  const classrooms = getClassroomsForCertificatesUser(state);

  return {
    userLegalAge: isUserLegalAge(state),
    classrooms,
  };
}

export default connect(mapStateToProps)(Certificate);
