import React, { useState, useCallback } from "react";
import { spacing } from "./theme";
import { css } from "glamor";

const triggerClass = css({
  position: "relative",
  display: "inline-block",
});

const contentBase = css({
  position: "absolute",
  zIndex: 1,
});

const getPositionStyle = (position, offset = spacing.m) => {
  switch (position) {
    case "top":
      return {
        top: `calc(-100% - ${offset})`,
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        alignItems: "center",
      };
    case "bottom":
      return {
        top: "100%",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        alignItems: "center",
      };
    case "left":
      return {
        top: "50%",
        left: "-100%",
        transform: "translateY(-50%)",
        display: "flex",
        alignItems: "center",
      };
    case "right":
      return {
        top: "50%",
        left: "100%",
        transform: "translateY(-50%)",
        display: "flex",
        alignItems: "center",
      };
    default:
      return {};
  }
};

export default function Float({
  position = "top",
  content,
  trigger,
  offset,
  isActive,
  style,
}) {
  const contentPosition = getPositionStyle(position, offset);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const contentClass = css(contentBase, contentPosition);

  const handleContentClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={triggerClass}
      style={style}
    >
      {trigger}

      {(isHovered || isActive) && <div className={contentClass}>{content}</div>}
    </div>
  );
}
