const initialState = {
  loading: true,
};

export default function reportsUtilsReducer(state = initialState, action) {
  switch (action.type) {
    case "SURVEYS_USERS_FETCH_REQUESTED":
      return { loading: true };
    case "SURVEYS_USERS_FETCHED":
      return { loading: false };
    default:
      return state;
  }
}
