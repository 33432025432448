import React from "react";
import { css } from "glamor";
import { Grid } from "semantic-ui-react";
import Image from "../library/Image";
import Logo from "../library/images/logo.svg";
import { spacing, uiColors } from "../library/theme";
import LobbyBackground from "./bg-lobby.jpg";

const gridClass = css({ marginTop: "0 !important" });
const wrapper = css({
  maxWidth: 450,
  margin: "auto",
  marginTop: spacing.xxl,
  paddingLeft: spacing.m,
});
const rightColumn = css({
  height: "100vh",
  backgroundImage: `url(${LobbyBackground})`,
  backgroundColor: uiColors.background,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 50%",
  backgroundSize: "cover",
});
const logoStyle = { marginBottom: spacing.m, width: 200 };

export default function LobbyLayout({ children }) {
  return (
    <Grid
      textAlign="center"
      verticalAlign="middle"
      className={gridClass.toString()}
    >
      <Grid.Column computer={7} mobile={16}>
        <div className={wrapper}>
          <Image src={Logo} size="small" style={logoStyle} centered />
          {children}
        </div>
      </Grid.Column>
      <Grid.Column
        computer={9}
        only="computer"
        className={rightColumn.toString()}
      ></Grid.Column>
    </Grid>
  );
}
