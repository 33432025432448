import React from "react";
import { css } from "glamor";
import { stateColors } from "../library/theme";

const container = css({
  width: "100%",
  position: "relative",
  paddingBottom: "60.93%",
  height: 0,
});
const painel = css({
  overflow: "hidden",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: stateColors.empty,
});
const content = css({
  textAlign: "center",
});

export default function LessonBlocked({
  daysToUnlock,
  watchedPrevious,
  enrollmentCreatedAtDays,
  compliant,
  invalidMembership,
  hasEnrollment,
  isUnlocked,
}) {
  return (
    <div className={container}>
      <div className={painel}>
        <div className={content}>
          <h4>Aula bloqueada</h4>
          {!watchedPrevious && (
            <p>
              É necessário assistir a(s) aula(s) anterior(es) para assistir esta
              aula
            </p>
          )}
          {!isUnlocked && (
            <p>
              É necessário assistir ao conteúdo de um curso anterior para
              assistir esta aula
            </p>
          )}
          {enrollmentCreatedAtDays != null && daysToUnlock > 0 && (
            <p>
              Aguarde {daysToUnlock} {daysToUnlock > 1 ? " dias " : " dia "}{" "}
              para esta aula estar liberada
            </p>
          )}
          {!compliant && !invalidMembership && (
            <p>
              Sua Matrícula ou Carteira está expirada e/ou inválida. Por favor,
              contate o suporte em{" "}
              <a href="https://ibft.com.br" target="_blank">
                https://ibft.com.br
              </a>
            </p>
          )}
          {!compliant && invalidMembership && !hasEnrollment && (
            <p>
              Esse curso é exclusivo para alunos com Carteira. Adquira a sua em{" "}
              <a href="https://citrg.com/" target="_blank">
                https://citrg.com/
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
