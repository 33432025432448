import React from "react";
import { css } from "glamor";
import { spacing, decorationColors, Float } from "../library";
import { BiLike, BiHeart, BiMedal } from "react-icons/bi";
import { MdOutlineSignLanguage } from "react-icons/md";

const likeActionClass = css({
  zIndex: 3,
  height: 35,
  width: 35,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  ":hover": {
    cursor: "pointer",
  },
});
const likeLabelWrapper = {
  backgroundColor: "white",
  borderRadius: spacing.m,
};

const options = {
  default: {
    icon: <BiLike size={16} />,
    label: "Gostei",
    color: decorationColors.decorationGreen,
  },
  congrats: {
    icon: <MdOutlineSignLanguage size={16} />,
    label: "Parabéns",
    color: decorationColors.decorationOrange,
  },
  love: {
    icon: <BiHeart size={16} />,
    label: "Amei",
    color: decorationColors.decorationRed,
  },
  awesome: {
    icon: <BiMedal size={16} />,
    label: "Genial",
    color: decorationColors.decorationPurple,
  },
};

export default function LikeOption({ createLikeHandler, like, type }) {
  const likeIcon = options[type]?.icon;
  const likeLabel = options[type]?.label;
  const likeColor = options[type]?.color;

  const actionStyle = {
    backgroundColor: `${likeColor}30`,
    color: `${likeColor}`,
  };
  const labelClass = css(actionStyle, {
    padding: `${spacing.xxs} ${spacing.xs}`,
    borderRadius: spacing.m,
  });
  const likeLabelClass = css(labelClass, {
    color: likeColor,
    backgroundColor: `${likeColor}30`,
  });

  const activeInteractionClass = css(actionStyle);
  const isActive = like?.type == type;
  const likeClass = css(likeActionClass, isActive && activeInteractionClass, {
    ":hover": { color: likeColor, backgroundColor: `${likeColor}30` },
  });

  return (
    <Float
      content={
        <div style={likeLabelWrapper}>
          <div className={likeLabelClass}>{likeLabel}</div>
        </div>
      }
      offset={spacing.xs}
      trigger={
        <div onClick={createLikeHandler} className={likeClass}>
          {likeIcon}
        </div>
      }
    />
  );
}
