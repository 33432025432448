import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const level = new schema.Entity("levels");

export const fetchLevels = (dispatch, classroomId) => {
  dispatch({ type: "LEVELS_FETCH_REQUESTED" });
  apiGet(`/api/v1/classrooms/${classroomId}/levels`).then((response) => {
    dispatch({
      type: "LEVELS_FETCHED",
      ...normalize(response.body.data, new schema.Array(level)),
    });
  });
};
