import React from "react";
import { Ref, TextArea } from "semantic-ui-react";

export default function TextAreaInput({ inputRef, ...props }) {
  return (
    <Ref innerRef={inputRef}>
      <TextArea {...props} />
    </Ref>
  );
}
