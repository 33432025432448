import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const classroomsContractSchema = new schema.Entity("classroomsContracts");

export const fetchClassroomsContracts = (dispatch) => {
  return apiGet(`/api/v1/classrooms_contracts`).then((response) => {
    dispatch({
      type: "CLASSROOMS_CONTRACTS_FETCHED",
      ...normalize(response.body.data, new schema.Array(classroomsContractSchema)),
    });
  });
};
