import React from "react";
import { Table } from "semantic-ui-react";
import Button from "../library/Button";
import Link from "../library/Link";

export default function InvoiceListItem(props) {
  const { attachment } = props;

  return attachment ? (
    <Table.Row verticalAlign="middle">
      <Table.Cell width="5">{attachment?.name ?? "Nota Fiscal"}</Table.Cell>
      <Table.Cell width="1" textAlign="right">
        <Link
          component={Button}
          href={attachment.url}
          target="_blank"
          disabled={!attachment?.url}
        >
          Baixar
        </Link>
      </Table.Cell>
    </Table.Row>
  ) : null;
}
