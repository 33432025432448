import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactPlayer from "react-player";
import { css } from "glamor";
import { styles } from "./theme";

const container = css(styles.roundedCorners, {
  position: "relative",
  paddingBottom: "56.25%",
  width: "100%",
});
const videoStyle = css(styles.roundedCorners, {
  overflow: "hidden",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
});

const PROGRESS_INTERVAL = 15000;

export default function Video({ url, onProgressHandler, options, progress }) {
  if (!url) return null;
  const playerRef = useRef();
  const onDurationHandler = useCallback(
    (duration) => {
      if (progress) {
        const playedSeconds = duration * progress;
        playedSeconds && playerRef.current.seekTo(playedSeconds);
      }
    },
    [progress]
  );

  const [videoUrl, setVideoUrl] = useState(url);

  useEffect(() => {
    // Hack for not change URL's too fast before loading the previous content
    // and broke the player
    function loadWhenReady() {
      if (!playerRef.current.player.isLoading) {
        setVideoUrl(url);
        clearTimeout(timeout);
      }
    }
    loadWhenReady();
    const timeout = setTimeout(() => loadWhenReady(), 500);
    return () => clearTimeout(timeout);
  }, [url]);

  return (
    <div className={container}>
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        className={videoStyle}
        onProgress={onProgressHandler}
        onDuration={onDurationHandler}
        progressInterval={PROGRESS_INTERVAL}
        options={options}
        width="100%"
        height="100%"
        controls
      />
    </div>
  );
}
