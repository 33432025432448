import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { fetchPosts } from "../../processes/postProcesses";
import { createCommunitiesUser } from "../../processes/communityProcesses";
import { getCommunityById } from "../../selectors/communitySelectors";
import { getCommunityPosts } from "../../selectors/postSelectors";
import Community from "./Community";

function CommunityContainer(props) {
  const { dispatch, community, communityId } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPosts(dispatch, communityId)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch, communityId]);

  const requestAccessHandler = useCallback(() => {
    createCommunitiesUser(dispatch, communityId);
  }, [dispatch, communityId]);

  return (
    <Community
      loading={loading}
      requestAccessHandler={requestAccessHandler}
      {...props}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  const { communityId } = ownProps.match.params;

  return {
    communityId,
    community: getCommunityById(state, communityId),
    posts: getCommunityPosts(state, communityId),
  };
};

export default connect(mapStateToProps)(CommunityContainer);
